import * as microsoftTeams from "@microsoft/teams-js";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MainPage from './page/main';
import { SnackbarProvider } from 'notistack';
import { observer } from 'mobx-react-lite';
import { LoadingComponent } from '../src/components/loading';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { axiosStore, notiStore } from './store';

import { configure } from 'mobx';
configure({
  enforceActions: 'never',
});

const darkTheme = createTheme({
    palette: {
      mode: 'light',
    },
});

const authenMicrosoftTeams = async () => {
    try {
        microsoftTeams.authentication.getAuthToken({
            successCallback: (token) => {
                // notiStore.open({ message: 'New token fetched' });
                const ms_token = token.toString();
                // console.log('Auth token new fetch triggered from interceptor', ms_token)
                axiosStore.setBaseAccessToken(ms_token);
            }
        });
    } catch (error) {
        // notiStore.error(error, 'Error initializing Microsoft Teams SDK');
    }
}


const BodyApp = observer(() => {

    function isInTeams() {
        try {
            // Teams SDK adds a specific query parameter when running in Teams
            const queryParams = new URLSearchParams(window.location.search);
            return queryParams.has('context');
        } catch (e) {
            console.error('Error checking Teams context:', e);
            return false;
        }
    } 

    useEffect(() => {
        console.log('is in teams' + isInTeams())
        axiosStore.setInterceptor(authenMicrosoftTeams)
    }, [])

    return !!axiosStore.access_token && (
        <SnackbarProvider maxSnack={2}>
            <LoadingComponent />
            <MainPage />
        </SnackbarProvider>
    );
})

// const ProtectedBodyApp = ({ms_token}) => {
//     // const location = useLocation();
//     // const params = new URLSearchParams(location.search);
//     // const token = params.get('token');

//     // if (!validateToken(token)) {
//     //     // If the token is not valid, return a styled 403 content.
//     //     return (
//     //         // <div className="flex justify-center items-center h-screen">
//     //         //     <div className="text-5xl font-bold text-red-500">
//     //         //         403 Forbidden
//     //         //     </div>
//     //         // </div>
//     //         <div>403 Forbidden</div>
//     //     );
//     // }

    

//     // If the token is valid, render the BodyApp component.
//     return <BodyApp/>;
// };


export const App = observer(({ms_token}) => {

    useEffect(() => {
        // console.log('is in teams' + isInTeams())
        axiosStore.setBaseAccessToken(ms_token);
        // authenMicrosoftTeams();
    }, [ms_token])
    return ms_token && (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Router>
                <Routes>
                    <Route path="*" element={<BodyApp />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
});

export default App;
