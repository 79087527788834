import React from 'react';

export const ImageModal = ({ isOpen, imageSrc, onClose }) => {
    if (!isOpen) return null;

    const handleImageClick = () => {
        const link = document.createElement('a');
        link.href = 'https://scgo365.sharepoint.com/sites/Corpservices/SitePages/GINII-(-SCG-Private-Chat-GPT-).aspx';
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

  
    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <button className="close-button" onClick={onClose}>X</button>
          <img src={imageSrc} alt="Modal" className="modal-image" onClick={handleImageClick} />
        </div>
      </div>
    );
  };
  