import { makeAutoObservable  } from 'mobx';
import { authService, userService } from '../service';
import { axiosStore, notiStore } from '.';
import { UserRole } from '../enums/user';
import { GroupType } from '../enums/group';
import { Group } from '@mui/icons-material';
import { getManageTabList } from '../enums/admin';

class AuthStore {
    user = null;
    // All possible group that user can access
    access_group_list = []

    constructor() {
        makeAutoObservable(this);
    }

    // get private_access_group_list() {
    //     return this.access_group_list.filter((group) => group.group_type == GroupType.Private)
    // }

    get maintain_group_list(){
        if(this.isAdmin){
            return this.access_group_list
        } else {
            return this.access_group_list.filter(group => group.admin_list.filter(u => u.oid == this.user.oid).length > 0)
        }
    }

    get maintain_private_group_list() {
        if(this.isAdmin){
            return this.access_group_list.filter(group => group.group_type == GroupType.Private)
        } else {
            return this.access_group_list.filter(group => group.group_type === GroupType.Private && group.admin_list.filter(u => u.oid == this.user.oid).length > 0)
        }
    }

    get maintain_public_group_list() {
        if(this.isAdmin){
            return this.access_group_list.filter(group => group.group_type == GroupType.Public)
        } else {
            return this.access_group_list.filter(group => group.group_type === GroupType.Public && group.admin_list.filter(u => u.oid == this.user.oid).length > 0)
        }
    }

    get manage_tab_list() {
        if(this.user){
            return getManageTabList(this.user, this.maintain_group_list, this.maintain_private_group_list)
        } else {
            return []
        }
    }

    get settingPaneAccessible() {
        return this.isAdmin || (this.isOwner && this.manage_tab_list.length > 0)
    }

    get isFirstLogin() {
        return this.user ? this.user.is_first_time : false;
    }

    async fetchUser() {
        try {
            const user = await userService.me();
            if (!user) {
                // this.signout();
                return;
            }
            this.setUser(user);
            // notiStore.open({ 'message': 'Fetch user done'})
        } catch (error) {
            notiStore.error(error, 'Cannot fetch user information');
            this.setUser(null);
            // this.signout();
        }
    }

    setUser(user) {
        this.user = user;
    }

    get isAuthenticated() {
        return !!axiosStore.access_token && !!this.user;
    }

    get isAdminOrOwner() {
        return this.isOwner || this.isAdmin;
    }

    // get settingPaneAccessible() {
    //     if (this.isAdmin) {
    //         return true;
    //     } else if (this.isOwner) {
    //         // return 
    //         return true
    //     } else {
    //         return false;
    //     }
    // }

    
    get isOwner() {
        return this.user ? this.user.role === UserRole.Owner : false;
    }

    get isAdmin() {
        return this.user ? this.user.role === UserRole.Admin : false;
    }

    // get isAdmin() {
    //     return this.user ? this.user.role === UserRole.Admin : false;
    // }
}

export default AuthStore;