export const PromptConfigMode = {
    GroupFile: 'group-file',
    General: 'general',
    PersonalFile: 'personal-file'
}

export const PromptConfigModeDisplay = {
    GroupFile: 'Group',
    General: 'General',
    PersonalFile: 'Personal file'
}

export const ResponseStyle = {
    Creative: 'Creative',
    Balance: 'Balance',
    Precise: 'Precise'
}

export const PromptVersion = {
    Version1: 'Version 1',
    Version2: 'Version 2',
    Version3: 'Version 3',
    Version4: 'Version 4',
    Version5: 'Version 5'
}

export const PromptVersionDisplay = {
    Version1: 'Preset 1',
    Version2: 'Preset 2',
    Version3: 'Preset 3',
    Version4: 'Preset 4',
    Version5: 'Preset 5'
}

export const RetrievalFilter = {
    None: 'None',
    High: 'High',
    Dynamic: 'Dynamic'
}

export const ModelOption = {
    // AzureOpenAI_GPT_3_5: 'azure-gpt-3.5',
    // AzureOpenAI_GPT_4_0: 'azure-gpt-4.0',
    AzureOpenAI_GPT_4_O: 'azure-gpt-4o'
}

export const ModelDisplay = {
    // AzureOpenAI_GPT_3_5: 'GPT 3.5',
    // AzureOpenAI_GPT_4_0: 'GPT 4',
    AzureOpenAI_GPT_4_O: 'GPT 4o'
}

