import React from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import UserMangementTable from "./admin/user";
import GroupManagementTable from "./admin/group";
import AssetManagementTable from "./admin/asset";
import { AdminManageTab } from "../enums/admin";
import { AdminManageTabArrayIcon } from "../enums/admin";
import GroupUserManagementTable from "./admin/groupUser";
import { useEffect } from "react";
import { authStore, dialogStore } from "../store";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import ClickAwayListener from '@mui/material/ClickAwayListener';
import SettingsComponent from "./admin/prompt";
// import AssetManagementTableV2 from './admin/folder_v2';

export const AdminModal = observer(({ isOpen, handleAdminModal, allMode }) => {
  const [mode, setMode] = React.useState(allMode[0]);
  // const promptSettingModeList = ['No File ( Admin )', 'File', 'Group']

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
    paddingLeft: "0px",
    overflow: "auto",
    maxHeight: "98%",
    display: "flex",
  };

  const renderSettingComponent = () => {
    switch (mode) {
      case AdminManageTab.ManageUser:
        return <UserMangementTable />;
      // Can manage group file for every group
      case AdminManageTab.ManageGroup:
        return <GroupManagementTable />;
      // Manage group user only for private group
      case AdminManageTab.ManagePrivateGroupUser:
        return (
          <GroupUserManagementTable
            group_list={toJS(authStore.maintain_private_group_list)}
          />
        );
      // Can manage group file for every group
      case AdminManageTab.ManageFolder:
        return (
          <AssetManagementTable
            group_list={toJS(authStore.maintain_group_list)}
          />
        );
      // case AdminManageTab.ManageFolderV2:
      //   return <AssetManagementTableV2 group_list={toJS(authStore.maintain_group_list)} />
      // Can manage group file for every group
      case AdminManageTab.ManagePrompt:
        return (
          <SettingsComponent group_list={toJS(authStore.maintain_group_list)} />
        );
    }
  };

  const getPaneList = () => {
    const allModeIcon = AdminManageTabArrayIcon;
    return allMode.map((insideMode, index) => {
      const IconComponent = allModeIcon[index];
      const iconSize = "medium";
      return (
        <Button
          key={index}
          sx={{
            height: "80px",
            width: "100%", // Full width
            backgroundColor: insideMode == mode ? "#016D77" : "#DBF1EF",
            color: insideMode == mode ? "white" : "#4F4F4F",
            "&:hover": {
              backgroundColor: "#016D77",
              color: "white",
              border: 0,
              // Remove borders on hover if needed, not explicitly stated here
            },
            border: "none", // Ensures no border
            fontFamily: "Sarala", // Custom font family
            fontSize: "11px", // Font size
            fontWeight: 700, // Font weight
            lineHeight: "18px", // Line height
            textAlign: "center", // Text alignment
            boxShadow: 0,
            display: "flex",
            flexDirection: "column", // Arrange children in a column
            justifyContent: "center",
            border: 0,
            borderRadius: 0,
          }}
          variant="contained"
          onClick={() => onChangeMode(insideMode)}
        >
          <div>
            {IconComponent ? <IconComponent fontSize={iconSize} /> : null}
          </div>
          <div>{insideMode}</div>
        </Button>
      );
    });
  };

  const onChangeMode = (mode) => {
    if (dialogStore.presetChanged && mode != AdminManageTab.ManagePrompt) {
      dialogStore.open(
        "Are you sure to exit, changed is still not applied",
        () => {},
        () => {
          setMode(mode);
        }
      );
    } else {
      setMode(mode);
    }
  };

  useEffect(() => {
    // console.log('AdminModal is open:', isOpen)
    // groupService.fetchAllGroup()
    // if(authStore.user) {
    //   const modes = getManageTabList(authStore.user, authStore.maintain_group_list, authStore.maintain_private_group_list)
    //   setAllMode(modes)
    // }
  }, [isOpen]);

  // const handleClickAway = (event) => {
  //   if (dialogStore.presetChanged) {
  //     dialogStore.open("Are you sure to clickaway?", ()=>{}, ()=>{
  //       handleAdminModal()
  //     })

  //   } else {
  //     handleAdminModal()
  //   }
  // };

  return (
    <Modal
      open={isOpen}
      onClose={handleAdminModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/* <ClickAwayListener onClickAway={handleClickAway}> */}
      <Box sx={modalStyle}>
        {/* Outermost Box to control the overall layout, set to flexDirection: 'column' */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column", // Ensures vertical stacking of the top bar and content areas
            width: "100%",
            height: "90vh", // Full viewport height, adjust as needed
          }}
        >
          {/* First row for the top bar menu */}
          <Box
            sx={{
              width: "100%",
              height: "40px",
              backgroundColor: "white",
              color: "#016D77",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between", // Ensures that elements are spaced out
              padding: "0 20px", // Adds padding to both sides
              boxSizing: "border-box", // Ensures padding is included in width calculation
            }}
          >
            <h3 style={{ marginLeft: "40px", fontSize: "24px" }}>Setting</h3>{" "}
            {/* No margin for better alignment */}
            <IconButton
              onClick={handleAdminModal}
              aria-label="close"
              sx={{
                color: "#016D77", // Set the color as needed
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Second row for the sidebar and main content area */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row", // Changed to row to place sidebar and main content side by side
              width: "100%",
              height: "calc(100vh - 70px)", // Remaining height after top bar
            }}
          >
            {/* Sidebar Menu */}
            <Box
              sx={{
                width: "10%",
                borderRight: "1px solid #ddd",
                backgroundColor: "#DBF1EF",
                color: "black",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                height: "100%", // Full height of the parent container
              }}
            >
              {getPaneList()}
            </Box>

            {/* Main Content Area */}
            <Box
              sx={{
                width: "90%", // Adjusted width
                paddingLeft: "14px",
                height: "100%", // Full height of the container
              }}
            >
              {renderSettingComponent()}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* </ClickAwayListener> */}
    </Modal>
  );
});
