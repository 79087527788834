import React, { useEffect, useState } from 'react';
import { Drawer, Tabs, Tab, Box, Button, TextField, List, ListItem, Icon, CircularProgress, Stack, Collapse, Menu, MenuItem } from '@mui/material';
import ConfirmDialog from './confirmDialog'; // Import ConfirmDialog
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UploadIcon from '@mui/icons-material/UploadFile'; // Import the upload icon
import CircleIcon from '@mui/icons-material/Circle';
import Checkbox from '@mui/material/Checkbox';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { debounce, isEqual } from 'lodash';
import { withStyles } from '@mui/styles';
import ExpanderFile from './expanderFile';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';


import { authStore, loadingStore, fileModalStore, notiStore, sessionStore } from '../store';
import { toJS } from 'mobx';
import { fileGroupService, fileService } from '../service';
import { observer } from 'mobx-react-lite';
import { FileStatus, FileMode } from '../enums/file';
import { isMobile } from 'react-device-detect';

import { configure } from 'mobx';
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@mui/icons-material';
configure({
  enforceActions: 'never',
});

const RightModal = observer(({ isOpen, toggleDrawer, setDrawerOpen, fetchNewFile }) => {
    const [searchText, setSearchText] = useState('')
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [confirmAction, setConfirmAction] = useState(null);
    const [confirmMessage, setConfirmMessage] = useState('Are you sure you want to perform this action?');
    const [groupAssets, setGroupAsset] = useState([])
    const [uniqueGroups, setUniqueGroups] = useState([])
    const [selectedPersonalFiles, setPersonalFileSelected] = useState([])

    // State to control which folder/sub-folder is currently expanded
    const [openFolderIds, setOpenFolderIds] = useState([]);
    const [openSubFolderIds, setOpenSubFolderIds] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    // const [selectedGroupFiles, setSelectedGroupFiles] = useState({});
    // const [sessionStore.selectedSubFolders, setSelectedSubFolders] = useState({});

    const togglePersonalFile = (value, fileMode, fileStatus) => () => {
        if(fileStatus == FileStatus.Failed) {
            return
        }
        if (fileMode != FileMode.Personal) {
            return
        }
        const index = selectedPersonalFiles.findIndex(file => file._id === value._id);
        if (index !== -1) {
            setPersonalFileSelected([...selectedPersonalFiles.filter((_, i) => i !== index)]);
        } else {
            setPersonalFileSelected([...selectedPersonalFiles, value]);
        }
    };

    const checkPersonalFileExistInSelected = (value) => {
        const index = selectedPersonalFiles.findIndex(file => file._id === value._id);
        return index !== -1;
    };

    const CustomCheckbox = withStyles({
            root: {
              color: '#E4E4E4',
              '&$checked': {
                color: '#016D77',
              },
            },
            checked: {},
          })(Checkbox);
    

    const FileActionButton = ({ Icon, oc, hide=false }) => {
        return !hide && (
            <IconButton style={{ fontSize: '20px', padding: '1px' }} onClick={(event) => {
                event.stopPropagation();
                if (oc) {
                    oc();
                }
            }}>
                <Icon style={{ color: 'rgba(0, 0, 0, 0.87)' }} />
            </IconButton>
        );
    };
    

    const handleTextChange = (e) => {
        setSearchText(e.target.value)
    }

    const debounceTextChange = debounce(handleTextChange, 150);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            if (sessionStore.personal_files.map(item => item['name']).includes(file.name)) {
                setConfirmMessage('File already exists, do you want to replace it?');
                setConfirmAction(() => async () => {
                    await uploadFile(file);
                    document.getElementById("fileInput").value = null;
                });
                setIsConfirmDialogOpen(true);
                return;
            }
            await uploadFile(file);
        }
    };
    
    const uploadFile = async (file) => {
        try {
            loadingStore.fileUploading = true;
            await fileService.upload_file(file, "blob", "private");
            await fetchNewFile();
            notiStore.open({ message: "File upload successfully" });
            loadingStore.fileUploading = false;
        } catch (error) {
            notiStore.error(error, "Error uploading file");
            console.error("Error uploading file:", error);
            loadingStore.fileUploading = false;
        }
    };
    

    const handleFileUpload = () => {
        document.getElementById("fileInput").click();
    }

    const UploadButton = observer(({ disabled = false }) => {
        return (
            <div>
                <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    accept=".pdf, .docx, .txt, .pptx,"
                    onChange={handleFileChange}
                />
    
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: 'white',
                        border: '0px',
                        boxShadow: 'none',
                        textTransform: 'none',
                        color: disabled ? 'grey' : 'black',
                        fontSize: '16px'
                    }}
                    disabled={disabled}
                    startIcon={loadingStore.fileUploading ? (
                        <div>
                            <CircularProgress size={15} style={{ color: 'grey'}} />
                        </div>
                    ) : <UploadIcon />}
                    onClick={handleFileUpload}
                >
                    Upload
                </Button>
                <p style={{ color: 'grey', fontSize: '10px', marginTop: '0px', marginLeft: '15px' }}>
                    Supported file format: pdf, docx, pptx, txt. (Max 10 MB)
                </p>
            </div>
        );
    })
    

    const FileStatusDisplay = ({ file }) => {
        // console.log('File ', file)
        // const statusColors = {
        //   init: 'red',
        //   'in-process': 'orange',
        //   done: 'green',
        //   failed: 'red',
        // };

        let display_status = ''
        let textColor = 'black'
        switch(file['status']){
            case FileStatus.Init:
                display_status = 'In queue'
                break
            case FileStatus.InProcess:
                display_status = 'Processing'
                break
            case FileStatus.Done:
                display_status = 'Done'
                break
            case FileStatus.Failed:
                display_status = 'Failed'
                textColor = 'red'
                break
        }
      
        return (
          <div className="right-modal-file">
            {file['name']}
            {file['status'] != FileStatus.Done && <span style={{ color: textColor, marginLeft: '3px' }}>
              [{display_status}]
            </span>}
          </div>
        );
    };

    const handleFolderClick = (event, id) => {
        event.preventDefault();
        event.stopPropagation();
        setOpenFolderIds(prev => {
            if (prev.includes(id)) {
                return prev.filter(folderId => folderId !== id);
            } else {
                return [...prev, id];
            }
        });
    };

    const handleSubFolderClick = (event, id) => {
        event.preventDefault();
        event.stopPropagation();
        setOpenSubFolderIds(prev => {
            if (prev.includes(id)) {
                return prev.filter(subFolderId => subFolderId !== id);
            } else {
                return [...prev, id];
            }
        });
    };

    const handleClick = (event, file) => {
        setAnchorEl(event.currentTarget);
        setSelectedFile(file);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFileView = () => {
        console.log("View file:", selectedFile);
        handleClose();
        // Implement view logic here
        fileModalStore.setFile(selectedFile);
        fileModalStore.open();
    };

    const handleFileDownload = async () => {
        handleClose()
        const file_uri = await fileGroupService.get_presigned_url(selectedFile['_id']);
        const link = document.createElement('a');
        link.href = file_uri;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        notiStore.open({ message: 'Download file successfully', role: 'success' });
    }

    const handleFileDelete = () => {
        console.log("Delete file:", selectedFile);
        handleClose();
        // Implement delete logic here
    };

    const toggleGroupFileSelection = (event, id, group_id, newState = null) => {
        event.preventDefault();
        event.stopPropagation();
        const uid = `${group_id}_${id}`
        const state = newState !== null ? newState : !sessionStore.selectedGroupFiles[uid];
        sessionStore.selectedGroupFiles = {
            ...sessionStore.selectedGroupFiles,
            [uid]: state
        }
        if (sessionStore.selectedAll[group_id] && state === false) {
            sessionStore.selectedAll = {
                ...sessionStore.selectedAll,
                [group_id]: false
            }
        }
    };

    const handleFolderSelection = (event, folderId, group_id, subFolderIds, fileIds, newState = null) => {
        event.preventDefault();
        event.stopPropagation();
        const folderUid = `${group_id}_${folderId}`
        const state = newState !== null ? newState : !sessionStore.selectedFolders[folderUid];
        sessionStore.selectedFolders = {
            ...sessionStore.selectedFolders,
            [folderUid]: state
        }
        sessionStore.selectedSubFolders = subFolderIds.reduce((acc, id) => {
            const subFolderUid = `${group_id}_${id}`
            return {
                ...acc,
                [subFolderUid]: state
            }
        }, {...sessionStore.selectedSubFolders});

        sessionStore.selectedGroupFiles = fileIds.reduce((acc, id) => {
            const uid = `${group_id}_${id}`
            return {
                ...acc,
                [uid]: state
            }
        }, {...sessionStore.selectedGroupFiles});

        if (sessionStore.selectedAll[group_id] && state === false) {
            sessionStore.selectedAll = {
                ...sessionStore.selectedAll,
                [group_id]: false
            }
        }
    };

    const handleSubFolderSelection = (event, subFolderId, group_id, fileIds, newState = null) => {
        event.preventDefault();
        event.stopPropagation();
        const subFolderUid = `${group_id}_${subFolderId}`
        const state = newState !== null ? newState : !sessionStore.selectedSubFolders[subFolderUid];
        sessionStore.selectedSubFolders = {
            ...sessionStore.selectedSubFolders,
            [subFolderUid]: state
        }
        sessionStore.selectedGroupFiles = fileIds.reduce((acc, id) => {
            const uid = `${group_id}_${id}`
            return {
                ...acc,
                [uid]: state
            }
        }, {...sessionStore.selectedGroupFiles});

        if (sessionStore.selectedAll[group_id] && state === false) {
            sessionStore.selectedAll = {
                ...sessionStore.selectedAll,
                [group_id]: false
            }
        }
    };

    const handleSelectAll = (event, group_id, rootFolders, subFolders, files) => {
        event.preventDefault();
        event.stopPropagation();
        const newState = !sessionStore.selectedAll[group_id];
        sessionStore.selectedAll = {
            ...sessionStore.selectedAll,
            [group_id]: newState
        }
        rootFolders.forEach(folder => handleFolderSelection(event, folder._id, group_id, [], [], newState));
        subFolders.forEach(subFolder => handleSubFolderSelection(event, subFolder._id, group_id, [], newState));
        files.forEach(file => toggleGroupFileSelection(event, file._id, group_id, newState));
    };

    const renderGroupAssets = ({ group_id }) => {
        const rootFolders = groupAssets.filter(asset => asset.asset_type === 'root_folder' && asset.group_id === group_id);
        const subFolders = groupAssets.filter(asset => asset.asset_type === 'sub_folder' && asset.group_id === group_id);
        const files = groupAssets.filter(asset => asset.asset_type === 'file' && asset.group_id === group_id);

        const searchTextFiles = files.filter(file => file.name.toLowerCase().includes(searchText.toLowerCase()));
        const searchTextSubFolders = subFolders.filter(subFolder => subFolder.name.toLowerCase().includes(searchText.toLowerCase()) || searchTextFiles.some(file => file.parent_id === subFolder._id));
        const searchTextRootFolders = rootFolders.filter(rootFolder => rootFolder.name.toLowerCase().includes(searchText.toLowerCase()) || searchTextSubFolders.some(subFolder => subFolder.parent_id === rootFolder._id) || searchTextFiles.some(file => file.parent_id === rootFolder._id));

        if (searchTextFiles.length === 0 && searchTextSubFolders.length === 0 && searchTextRootFolders.length === 0) {
            return null;
        }

        return (
            <Box sx={{ padding: 0 }}>
                <List>
                    <ListItem style={{backgroundColor: sessionStore.selectedAll[group_id] ? 'rgba(25, 118, 210, 0.08)' : 'transparent'}}>
                        <ListItemIcon sx={{minWidth: '0px'}}>
                            <Checkbox
                                checked={sessionStore.selectedAll[group_id] || false}
                                onClick={(event) => handleSelectAll(event, group_id, searchTextRootFolders, searchTextSubFolders, searchTextFiles)}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Select All" />
                    </ListItem>
                    {searchTextRootFolders.map((folder) => {
                        const folderFileIds = searchTextFiles.filter(file => file.parent_id === folder._id).map(file => file._id);
                        const folderSubFolderIds = searchTextSubFolders.filter(subFolder => subFolder.parent_id === folder._id).map(subFolder => subFolder._id);
                        const allFolderFileIds = folderSubFolderIds.flatMap(subFolderId => searchTextFiles.filter(file => file.parent_id === subFolderId).map(file => file._id)).concat(folderFileIds);
                        return (
                            <React.Fragment key={folder._id}>
                                <ListItem button onClick={(event) => handleFolderClick(event, folder._id)} style={{backgroundColor: sessionStore.selectedFolders[`${group_id}_${folder._id}`] ? 'rgba(25, 118, 210, 0.08)' : 'transparent'}}>
                                    <ListItemIcon sx={{minWidth: '0px'}}>
                                        <Checkbox
                                            checked={sessionStore.selectedFolders[`${group_id}_${folder._id}`] || false}
                                            onClick={(event) => handleFolderSelection(event, folder._id, group_id, folderSubFolderIds, allFolderFileIds)}
                                        />
                                    </ListItemIcon>
                                    <ListItemIcon sx={{minWidth: '0px', marginRight: '2px'}}>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={`${folder.name} (${allFolderFileIds.length > 1 ? `${allFolderFileIds.length} files` : `${allFolderFileIds.length} file`})`} />
                                </ListItem>
                                <Collapse in={openFolderIds.includes(folder._id)} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {searchTextSubFolders.filter(subFolder => subFolder.parent_id === folder._id).map((subFolder) => {
                                            const subFolderFileIds = searchTextFiles.filter(file => file.parent_id === subFolder._id).map(file => file._id);
                                            return (
                                                <React.Fragment key={subFolder._id}>
                                                    <ListItem button sx={{ pl: 4 }} onClick={(event) => handleSubFolderClick(event, subFolder._id)} style={{backgroundColor: sessionStore.selectedSubFolders[`${group_id}_${subFolder._id}`] ? 'rgba(25, 118, 210, 0.08)' : 'transparent'}}>
                                                        <ListItemIcon sx={{minWidth: '0px'}}>
                                                            <Checkbox
                                                                checked={sessionStore.selectedSubFolders[`${group_id}_${subFolder._id}`] || false}
                                                                onClick={(event) => handleSubFolderSelection(event, subFolder._id, group_id, subFolderFileIds)}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemIcon sx={{minWidth: '0px'}}>
                                                            <FolderOpenIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary={`${subFolder.name} (${subFolderFileIds.length > 1 ? `${subFolderFileIds.length} files` : `${subFolderFileIds.length} file`})`} />
                                                    </ListItem>
                                                    <Collapse in={openSubFolderIds.includes(subFolder._id)} timeout="auto" unmountOnExit>
                                                        <List component="div" disablePadding>
                                                            {searchTextFiles.filter(file => file.parent_id === subFolder._id).map((file) => (
                                                                <ListItem button sx={{ pl: 8 }} key={file._id} style={{backgroundColor: sessionStore.selectedGroupFiles[`${file.group_id}_${file._id}`] ? 'rgba(25, 118, 210, 0.08)' : 'transparent'}}>
                                                                    <ListItemIcon sx={{minWidth: '0px'}}>
                                                                        <Checkbox
                                                                            checked={sessionStore.selectedGroupFiles[`${file.group_id}_${file._id}`] || false}
                                                                            onClick={(event) => {
                                                                                event.stopPropagation();
                                                                                toggleGroupFileSelection(event, file._id, group_id)
                                                                            }}
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={file.name} secondary={file.updated_at} secondaryTypographyProps={{color: 'text.secondary'}}/>
                                                                    <ListItemSecondaryAction>
                                                                        <IconButton edge="end" onClick={(event) => handleClick(event, file)}>
                                                                            <MoreVertIcon />
                                                                        </IconButton>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </Collapse>
                                                </React.Fragment>
                                            );
                                        })}
                                        {searchTextFiles.filter(file => file.parent_id === folder._id).map((file) => (
                                            <ListItem button sx={{ pl: 4 }} key={file._id} style={{backgroundColor: sessionStore.selectedGroupFiles[`${file.group_id}_${file._id}`] ? 'rgba(25, 118, 210, 0.08)' : 'transparent'}}>
                                                <ListItemIcon sx={{minWidth: '0px'}}>
                                                    <Checkbox
                                                        checked={sessionStore.selectedGroupFiles[`${file.group_id}_${file._id}`] || false}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            toggleGroupFileSelection(event, file._id, group_id)
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={file.name} secondary={file.updated_at} secondaryTypographyProps={{color: 'text.secondary'}}/>
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" onClick={(event) => handleClick(event, file)}>
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            </React.Fragment>
                        );
                    })}
                </List>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: 48 * 4.5,
                            width: '20ch',
                        },
                    }}
                >
                    <MenuItem onClick={handleFileView}>
                        <ListItemIcon>
                            <VisibilityIcon fontSize="small" />
                        </ListItemIcon>
                        View
                    </MenuItem>
                    <MenuItem onClick={handleFileDownload}>
                        <ListItemIcon>
                            <DownloadIcon fontSize="small" />
                        </ListItemIcon>
                        Download
                    </MenuItem>
                    {/* <MenuItem onClick={handleFileDelete}>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        Delete
                    </MenuItem> */}
                </Menu>
            </Box>
        );
    };

    const renderPersonalTab = () => {
        return (
            <Box sx={{ padding: 0 }}>
                
                <List>
                    {sessionStore.personal_files
                    .filter((file)=> file['name'].toLowerCase().includes(searchText.toLowerCase()))
                    .map((file, index) => (
                        <ListItem
                            key={index}
                            role={undefined}
                            dense
                            button
                            onClick={togglePersonalFile(toJS(file), FileMode.Personal, toJS(file)['status'])}
                            disabled={![FileStatus.Failed, FileStatus.Done].includes(file['status'])}
                            selected={checkPersonalFileExistInSelected(file)}
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                                <ListItemIcon>
                                    {/* {file['status'] == FileStatus.Done && <CustomCheckbox
                                        icon={<CheckBoxOutlineBlankOutlined />}
                                        checkedIcon={<CheckBox />}
                                        checked={checkPersonalFileExistInSelected(toJS(file))}
                                    />} */}
                                    {file['status'] == FileStatus.Done && <Checkbox
                                        checked={checkPersonalFileExistInSelected(toJS(file))}
                                    />}
                                </ListItemIcon>
                                <ListItemText 
                                    primary={
                                        <FileStatusDisplay file={file} />
                                    } 
                                />
                            </div>
                            {[FileStatus.Done, FileStatus.Failed].includes(file['status']) && <div style={{ display: 'flex', alignItems: 'center' }}>
                                <ListItemSecondaryAction>
                                    <span style={{ marginRight: '5px', color: '#9C9C9C', fontSize: '12px' }}>
                                        {new Date(file['created_at']).toLocaleDateString()}
                                    </span>
                                    <FileActionButton hide={file['status'] == FileStatus.Failed} Icon={VisibilityIcon} oc={() => {
                                        fileModalStore.setFile(file);
                                        fileModalStore.open();
                                    }} />
                                    <FileActionButton hide={file['status'] == FileStatus.Failed} Icon={DownloadIcon} oc={() => {
                                        setConfirmMessage('Are you sure you want to download this file?');
                                        setConfirmAction(() => async () => {
                                            const file_uri = await fileService.get_presigned_url(file['_id']);
                                            const link = document.createElement('a');
                                            link.href = file_uri;
                                            link.target = '_blank';
                                            document.body.appendChild(link);
                                            link.click();
                                            document.body.removeChild(link);
                                            notiStore.open({ message: 'Download file successfully', role: 'success' });
                                        });
                                        setIsConfirmDialogOpen(true);
                                    }} />
                                    <FileActionButton Icon={DeleteIcon} oc={() => {
                                        setConfirmMessage('Are you sure you want to delete this file?');
                                        setConfirmAction(() => async () => {
                                            await fileService.delete_file(file['_id']);
                                            sessionStore.personal_files = sessionStore.personal_files.filter(item => item['_id'] != file['_id']);
                                            notiStore.open({ message: 'Delete file successfully', role: 'success' });
                                        });
                                        setIsConfirmDialogOpen(true);
                                    }} />
                                </ListItemSecondaryAction>
                            </div>}

                        </ListItem>
                    ))}
                </List>
            </Box>
            
        );
    };

    const renderNestedExpander = () => {
        return authStore.access_group_list.length > 0 && groupAssets.length > 0 && (
            <div>
                { authStore.access_group_list.map(group => {
                    const title = `Group ${group.name}`
                    if (searchText.length > 0) {
                        const files = groupAssets.filter(asset => asset.asset_type === 'file' && asset.group_id === group.id && asset.name.toLowerCase().includes(searchText.toLowerCase()));
                        if(files.length == 0){
                            return null
                        }
                        console.log(group.name, files)
                    }
                    //console.log(group.id);
                    const isGroupSelected = Object.keys(sessionStore.selectedGroupFiles).some(key => {
                        const [groupId, fileId] = key.split('_');
                        return groupId === group.id && sessionStore.selectedGroupFiles[key] === true;
                    });
                    return (
                        <ExpanderFile title={title} detail='' defaultExpanded={false} isChildSelected={isGroupSelected}>
                            {renderGroupAssets({group_id: group.id})}
                        </ExpanderFile>
                    );
                })}
            </div>
        );
    };

    // Fetch all group file that person should be accessible to
    const onStart = async () => {
        let data = await fileGroupService.fetch_all_group_access_files();
        data = data.filter(mem => mem.status ? mem.status == 'done': true)
        const uniqueGroupIds = [...new Set(data.map(item => item.group_id))];
        setUniqueGroups(uniqueGroupIds)
        setGroupAsset(data)
    }

    useEffect(() => {
        let interval = null
        // If open, Set interval to poll for new files
        if (isOpen && sessionStore.isFileProcess ) {
            interval = setInterval(async () => {
                await fetchNewFile()
                await onStart()
            }, 10000);

            return () => clearInterval(interval);
        }
        if (!isOpen) {
            if(interval){
                clearInterval(interval)
            }
        }
    }, [isOpen, sessionStore.isFileProcess])

    useEffect(()=> {
        console.log('=========== Selected All ==============')
        console.log(sessionStore.selectedAll)
    }, [sessionStore.selectedAll])

    // Reset text
    useEffect(() => {
        setSearchText('')
    }, [isOpen])


    useEffect(() => {
        onStart()
    }, [])


    // // Sync up selected file to store
    // useEffect(() => {
    //     if(selectedGroupFiles){
    //         sessionStore.selectedGroupFiles = selectedGroupFiles
    //     }
    //     return () => {
    //         // Did nothing here
    //     }
    // }, [selectedGroupFiles])

    // useEffect(() => {
    //     // console.log('sth change')
    //     // console.log(sessionStore.selectedGroupFiles)
    //     // console.log(selectedGroupFiles)
    //     if(!isEqual(sessionStore.selectedGroupFiles,selectedGroupFiles)){
    //         setSelectedGroupFiles({...toJS(sessionStore.selectedGroupFiles)})
    //     }
    // }, [sessionStore.selectedGroupFiles])


    useEffect(() => {
        if(selectedPersonalFiles){
            sessionStore.selectedPersonalFiles = selectedPersonalFiles
        }
        return () => {
            // Did nothing here
        }
    }, [selectedPersonalFiles])


    useEffect(() => {
        if(!isEqual(sessionStore.selectedPersonalFiles,selectedPersonalFiles)){
            setPersonalFileSelected(sessionStore.selectedPersonalFiles)
        }
    }, [sessionStore.selectedPersonalFiles])


    // useEffect(() => {
    //     if(!sessionStore.currentFile){
    //         setSelectedGroupFiles([])
    //     }
    // }, [isOpen])

    return (
        <Drawer
            anchor='right'
            open={isOpen}
            onClose={toggleDrawer(false)}
	        PaperProps={{
                style: {
                    width: isMobile ? '95%' : '600px',
                },
            }}
        >
            <Box
                role="presentation"
                // onKeyDown={toggleDrawer(false)}
            >
                <Box sx={{ padding: 2 }}>
                <TextField
                    fullWidth
                    placeholder="Search files..."
                    margin="normal"
                    onChange={debounceTextChange}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <UploadButton />
                    {/* <span style={{color: '#016D77'}}>{selectedGroupFiles.length > 1 ? selectedGroupFiles.length + " files selected" : selectedGroupFiles.length + " file selected"}</span> */}
                </Box>
                </Box>
                <ExpanderFile title='Personal File' detail=''>
                    {renderPersonalTab()}
                </ExpanderFile>
                {renderNestedExpander()}
                <ConfirmDialog 
                    open={isConfirmDialogOpen}
                    onClose={() => setIsConfirmDialogOpen(false)}
                    onConfirm={() => { confirmAction && confirmAction(); setIsConfirmDialogOpen(false); }}
                    message={confirmMessage}
                />
            </Box>
        </Drawer>
    );
});

export default RightModal;
