import IconButton from "@mui/material/IconButton";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Chip, Tooltip } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { MessageRole, MessageSentiment } from "../enums/message";
import { qaService } from "../service";
import { ThumbsUpDown } from "@mui/icons-material";

export const MessageArea = observer(({ messages, setMessages, dotCount }) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

    const triggerMessageSentiment = async (index, sentiment) => {
        // Set sentiment neutral if clicked on the same sentiment
        let newSentiment = null
        if (messages[index].sentiment === sentiment) {
            newSentiment = MessageSentiment.Neutral
        } else {
            newSentiment = sentiment
        }

        // If sentiment is changed, send the sentiment to backend
          if (messages[index].sentiment !== newSentiment) {
            // Send the sentiment to backend
            await qaService.update_sentiment(messages[index].id, newSentiment)
        }

        // Update the message
        const newMessages = [...messages]
        newMessages[index].sentiment = newSentiment
        setMessages(newMessages)
    }


    

  useEffect(scrollToBottom, [messages]);

  return (
    <div
      style={{
        flex: 1,
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {messages.map((message, index) => (
        <div key={index} style={{ marginBottom: "25px", marginRight: "10px" }}>
          <div
            style={{
              textAlign: message.role === MessageRole.User ? "right" : "left",
              marginBottom: "5px",
              paddingLeft: message.role === MessageRole.User ? "0px" : "50px",
              fontSize: "10px",
              color: "grey",
            }}
          >
            {message.timestamp}
            {/* {new Date(message.timestamp).toLocaleString("en-US", {
              day: "numeric",
              month: "short",
              weekday: "short",
              hour: "2-digit",
              minute: "2-digit",
            })} */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems:
                message.role === MessageRole.User ? "flex-end" : "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent:
                  message.role === MessageRole.User ? "flex-end" : "flex-start",
              }}
            >
              {message.role !== MessageRole.User && (
                <img
                  src="/ginii.png"
                  alt="Ginii Chat Icon"
                  width="40"
                  height="40"
                  style={{ marginRight: "8px" }}
                />
              )}
              <div
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  borderRadius: "5px",
                  backgroundColor:
                    message.role === MessageRole.User ? "#EDF7F9" : "#F3F2F3",
                  width: "fit-content",
                  wordWrap: "break-word", // Added to break long words
                  overflowWrap: "break-word",
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                  maxWidth:
                    message.role === MessageRole.User
                      ? "calc(100%)"
                      : "calc(100%)",
                  marginLeft: message.role === MessageRole.User ? "auto" : "0",
                  marginRight: message.role === MessageRole.User ? "0" : "auto",
                  lineHeight: message.role === MessageRole.User ? "1" : "1.4",
                }}
              >
                {message.isTyping ? (
                  <span>GINII is thinking{".".repeat(dotCount)}</span>
                ) : (
                  <span>
                    {message.type === "markdown" || message.role === MessageRole.User ? (
                      <>
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          components={{
                            a: ({ node, ...props }) => (
                              <a
                                {...props}
                                target="_blank"
                                rel="noopener noreferrer"
                              />
                            ),
                            th: ({ node, ...props }) => (
                              <th
                                {...props}
                                style={{border: "1px solid black"}}
                              />
                            ),
                            td: ({ node, ...props }) => (
                              <td
                                {...props}
                                style={{border: "1px solid black"}}
                              />
                            ),
                            table: ({ node, ...props }) => (
                              <table
                                {...props}
                                className="table"
                                style={{border: "1px solid black"}}
                              />
                            ),
                          }}
                        >
                          {message.content}
                        </ReactMarkdown>
                        {message.role === MessageRole.Bot && (
                          <>
                            <hr style={{ margin: "10px 0" }} /> {/* Divider line */}
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "5px",
                              }}
                            >
                              {message.references &&
                                message.references.map((reference, refIndex) => (
                                  <Tooltip title={reference} key={refIndex}>
                                    <Chip
                                      label={reference}
                                      style={{
                                        width: '230px',
                                        background: 'white',
                                        color: 'black',
                                        boxShadow: '2px 1px 3px rgba(0, 0, 0, 0.3)'
                                      }}
                                    />
                                  </Tooltip>
                                ))
                              }
                            </div>
                          </>
                        )}

                      </>
                    ) : message.type === "html" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: message.content,
                        }}
                      />
                    ) : (
                      <span>{message.content}</span>
                    )}
                  </span>
                )}
                {message.role === MessageRole.Bot && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "-10px",
                    }}
                  >
                  {
                    !message.isTyping && (
                        <>
                            <IconButton
                                aria-label="thumbs up"
                                style={{color: "#77BFB8"}}
                                onClick={() => triggerMessageSentiment(index, MessageSentiment.Positive)}
                            >
                                {message.sentiment === MessageSentiment.Positive ? <ThumbUpIcon /> : <ThumbUpOffAltIcon />}
                            </IconButton>
                            <IconButton
                                aria-label="thumbs down"
                                style={{color: "#F8A59C"}}
                                onClick={() => triggerMessageSentiment(index, MessageSentiment.Negative)}
                            >
                                {message.sentiment === MessageSentiment.Negative ? <ThumbDownIcon /> : <ThumbDownOffAltIcon />}
                            </IconButton>
                        </>
                    )
                  }

                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
});
