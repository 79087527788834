export const FileStatus = {
    Init: 'init',
    InProcess: 'in-process',
    Done: 'done',
    Failed: 'failed'
}

export const FileMode = {
    None: 'none',
    Group: 'group',
    Personal: 'personal',
    MIX: 'mix'
}

// bmp	image/bmp	✓
// doc	application/msword	✓
// docx	application/vnd.openxmlformats-officedocument.wordprocessingml.document	✓
// htm	text/htm	✓
// html	text/html	✓
// jpg	image/jpg	✓
// jpeg	image/jpeg	✓
// pdf	application/pdf	✓
// png	image/png	✓
// ppt	application/vnd.ms-powerpoint	✓
// pptx	applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation	✓
// tiff	image/tiff	✓
// txt	text/plain	✓
// xls	application/vnd.ms-excel	✓
// xlsx	application/vnd.openxmlformats-officedocument.spreadsheetml.sheet	✓
// Init Mime type for pdf, xlsx, docx, pptx, txt
export const MiMeType = {
    DOC : 'application/msword',
    DOCX : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    PDF : 'application/pdf',
    PPT : 'application/vnd.ms-powerpoint',
    PPTX : 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    TXT : 'text/plain',
    XLS : 'application/vnd.ms-excel',
    XLSX : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    CSV: 'text/csv'    
}