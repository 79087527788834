import AssetService from './asset';
import AuthService from './auth';
import FileService from './file';
import FileGroupService from './file_group';
import FolderService from './folder';
import GroupService from './group';
import GroupUserService from './group_user';
import PresetService from './preset';
import QAService from './qa';
import QuotaService from './quota';
import SessionService from './session';
import UserService from './user';


export const userService = new UserService();
export const authService = new AuthService();
export const fileService = new FileService()
export const sessionService = new SessionService()
export const qaService = new QAService()
export const groupService = new GroupService()
export const groupUserService = new GroupUserService()
export const fileGroupService = new FileGroupService()
// export const folderService = new FolderService()
export const assetService = new AssetService()
export const presetService = new PresetService()
export const quotaService = new QuotaService()