import { adminStore, axiosStore, contextStore } from "../store";

class PresetService {
    async updatePreset(preset_id, preset) {
        await axiosStore.baseAxios.put(`/preset/${preset_id}`, {
            description: preset.description,
            base: preset.base,
            quota: preset.quota,
            retrieval_filter: preset.retrieval_filter
        })
    }


    async getDefaultPrompt(){
        const response = await axiosStore.baseAxios.get('/qa/default_prompt_template');
        contextStore.setDefaultPromptFormat(response.data)
    }

    async getPresetByGroupId(group_id, preset_version) {
        const response = await axiosStore.baseAxios.get(`/preset/group/${group_id}/${preset_version}`);
        return response.data;
    }

    async getModelByTypeAndMode(model_type, preset_mode, preset_version) {
        const response = await axiosStore.baseAxios.get(`/preset/model/${model_type}/mode/${preset_mode}/${preset_version}`);
        return response.data;
    }

    async updatePublishControl(mode, pid, publishControl) {
        const response = await axiosStore.baseAxios.put(`/preset/publish-control/${mode}/${pid}`, {
            published_version: publishControl.published_version,
            test_version: publishControl.test_version
        })
        return response.data
    }
}

export default PresetService