import { makeAutoObservable, observable } from 'mobx';

class LoadingStore {
    isLoading = false;
    loadingText = 'Loading...';
    fileUploading = false

    constructor() {
        makeAutoObservable(this, {
            isLoading: observable,
            loadingText: observable,
        });
    }

    setIsLoading = (isLoading) => {
        this.isLoading = isLoading;
    };

    setLoadingText = (loadingText) => {
        this.loadingText = loadingText;
    };
}

export default LoadingStore;
