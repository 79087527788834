import { adminStore, axiosStore, contextStore } from "../store";

class QuotaService {

    async getQuotaByGroupId(group_id) {
        const response = await axiosStore.baseAxios.get(`/preset/quota/${group_id}`);
        return response.data
    }

    async getPersonalQuota(){
        const response = await axiosStore.baseAxios.get(`/preset/quota-personal`);
        return response.data
    }

    async update(id, quota){
        const response = await axiosStore.baseAxios.put(`/preset/quota/${id}`, {
            "change": quota
        });
        return response.data
    }
}

export default QuotaService
