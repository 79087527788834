import React, { useState, useEffect, useRef } from "react";
import { fileService, groupService, qaService, sessionService } from "../service";
import {
  authStore,
  fileModalStore,
  notiStore,
  sessionStore,
  axiosStore,
  dialogStore,
} from "../store";
import SideBar from "../components/sidebar";
import FilePreviewModalCompoennt from "../components/fileModal";
import { MessageRole } from "../enums/message";
import { configure } from "mobx";
import { observer } from "mobx-react-lite";
import { FileStatus, FileMode } from "../enums/file";
import { FilterComponent } from "../components/filter";
import {MessageBar} from "../components/messageBar";
import { MessageArea } from "../components/messageArea";
import { isMobile } from 'react-device-detect';
import {ImageModal} from '../components/imageModal'
import { AdminModal } from "../components/adminModal";
import { getManageTabList } from "../enums/admin";
import ConfirmDialog from "../components/confirmDialog";

configure({
  enforceActions: "never",
});

const get_current_time_display = () => {
  return new Date().toLocaleString("en-US", {
    day: "numeric",
    month: "short",
    weekday: "short",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const MainPage = observer(() => {
  const [messages, setMessages] = useState([]);
  const [dotCount, setDotCount] = useState(1);
  const [isBotThinking, setIsBotThinking] = useState(false);
  const [currentSession, setCurrentSession] = useState(null);
  const [input, setInput] = useState("");
  const [sessionHistory, setSessionHistory] = useState([]);
  const [showSidebar, setShowSidebar] = useState(!isMobile);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [accessSettingModeList, setModeList] = useState([])
  // const [showSidebar, setShowSidebar] = useState(false)
  
  const handleAdminModal = () => {
    if(openAdmin && dialogStore.presetChanged){
      dialogStore.open('Are you sure to exit, changed is still not applied', ()=>{}, ()=>{
        setOpenAdmin(!openAdmin);
      })
    } else {
      setOpenAdmin(!openAdmin);
    }
  };

  useEffect(() => {
    if(authStore.isFirstLogin){
      setIsImageModalOpen(true);
    }
    // if(authStore.user) {
    //   const modes = getManageTabList(authStore.user, authStore.maintain_group_list, authStore.maintain_private_group_list)
    //   console.log('mode')
    //   console.log(modes)
    //   setModeList(modes)
    // }
  }, [authStore.user]);

  const sessionStartRef = useRef(null);
  const scrollToStart = () => {
    sessionStartRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSend = async () => {
    if (input.trim() === "") {
      return;
    }
    const userMessage = {
      role: MessageRole.User,
      content: input.replaceAll('\n', '\n\n'),
      type: 'markdown',
      timestamp:get_current_time_display(),
    };
    setInput("");

    const newSession = messages.length === 0 ? true : false;
    // alert('New session: ' + newSession)
    setMessages([...messages, userMessage]);

    // Add a bot message with isTyping set to true
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        role: MessageRole.Bot,
        content: "",
        isTyping: true,
        timestamp: get_current_time_display(),
      },
    ]);
    setIsBotThinking(true);

    try {
      const is_gonnabe_new_session = newSession || currentSession === null
      const response = is_gonnabe_new_session
        ? await sessionService.create_session(userMessage.content)
        : await qaService.ask(userMessage.content, currentSession.session_id);

      if (response) {
        setIsBotThinking(false);
        
        // Update the last message with the content and set isTyping to false
        if (is_gonnabe_new_session) {
          setCurrentSession(response.session);
          setSessionHistory([response.session, ...sessionHistory]);
        } else {
          // Reorder session history
          const updatedSessionHistory = sessionHistory.filter(session => session.session_id !== currentSession.session_id)
          const sessionToShift = {
            ...currentSession, "updated_at": new Date(),
            // "last_req_file_mode": sessionStore.currentFileMode ,
            "last_req_file_ids_v2": sessionStore.req_file_ids
          }
          updatedSessionHistory.unshift(sessionToShift)
          setSessionHistory(updatedSessionHistory)
          setCurrentSession(sessionToShift)

          scrollToStart()
        }

        console.log('response:', response);
        const references = response.qa_response.references.length > 0 ? response.qa_response.references.map(ref => ref.split('/')[ref.split('/').length-1]).filter(ref => !ref.includes('_summary.txt')) : []
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1] = {
            id: response.qa_response.id,
            role: MessageRole.Bot,
            content: response.qa_response.response_message,
            isTyping: false,
            type: response.qa_response.response_type,
            timestamp: get_current_time_display(),
            // reference: response.qa_response.response_source,
            references: references
            // 
          };
          return updatedMessages;
        });
      }
    } catch (error) {
      setIsBotThinking(false);

      // Check if error has message
      if (error.message) {
        notiStore.error(error, error.message);

        // Set ginii last message to error message
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1] = {
            role: MessageRole.Bot,
            content: 'Please retry again or contact admin for further support.',
            isTyping: false,
            type: 'error',
            timestamp: get_current_time_display(),
          };
          return updatedMessages;
        });
      } else {
        notiStore.error(
          error,
          "QA Service not available, please contact admin"
        );
      }
      console.error("Error fetching response from backend:", error);
    }
  };

  const fetchSessionHistory = async () => {
    try {
      const sessions = await sessionService.get_all_sessions();
      console.log("Current sessions: ", sessions);
      setSessionHistory(sessions);
      return sessions;
    } catch (error) {
      notiStore.error(error, "Error fetching chat history");
      console.error("Error fetching chat history:", error);
    }
  };

  const fetchNewFile = async () => {
    // Fetch file list
    const file_response = await fileService.fetch_access_files();
    console.log("File response main: ", file_response);

    sessionStore.personal_files = file_response["personal"].map((item) => {
      return {
        ...item,
        file_type: FileMode.Personal,
        display_name: item["name"]
      };
    });
  };

  const fetchAsset = async () => {
    try {
      await fetchNewFile();
      const sessions = await fetchSessionHistory();
      // Fetch current session chat history
      // Lets say chat session is sessionid@001
      // Auto select first session to render as always
      if (sessions && sessions.length > 0) {
        const last_index = sessions.length - 1;
        console.log("Current session: ", sessions[last_index]);
        setCurrentSession(sessions[0]);
        sessionStore.setFileFromMemory(sessions[0])
      } else {
        setMessages([]);
      }
      console.log("History ", messages);
    } catch (error) {
      notiStore.error(error, "Error fetching asset");
      console.error("Error fetching asset:", error);
    }
  };

  const fetchSession = async () => {
    if (currentSession !== null) {
      const messages = await sessionService.get_chat_history(
        currentSession.session_id
      );
      setMessages(messages);
    }
  };

  const fetchData = async () => {
    try {
      // await authStore.reset_user();
      await authStore.fetchUser();
      await fetchAsset();
    } catch (error) {
      notiStore.error(error, "Error fetching data");
      console.error("Error fetching data:", error);
    }
  };

  const handleNewChat = (event) => {
    // sessionStore.setDefaultFile();
    sessionStore.unSelectFile();
    setCurrentSession(null);
    setMessages([]);
  };

  const toggleSidebar = () => {
    // if(isMobile){
      setShowSidebar(!showSidebar);
    // }
  }

  // Create reference to the current session
  const prevSession = React.useRef(currentSession);

  useEffect(() => {
    // if(currentSession && prevSession.current != null) {
    //   sessionStore.unselectFile()
    // } else {
    //   sessionStore.setDefaultFile()
    // }
    fetchSession();
    // notiStore.open({message: "token:" + axiosStore.access_token })
    prevSession.current = currentSession;

  }, [currentSession]);

  const init = async () => {
    // await authenMicrosoftTeams()
    // axiosStore.setInterceptor(authenMicrosoftTeams)
    await fetchData()
    await groupService.fetchAllGroup()
  }

  useEffect(() => {
    init()
    // authenMicrosoftTeams();
    // axiosStore.setInterceptor(authenMicrosoftTeams)
    // fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let interval;
    if (isBotThinking) {
      interval = setInterval(() => {
        setDotCount((prevCount) => (prevCount % 4) + 1);
      }, 500);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isBotThinking]);

  return (
    <div>
      <SideBar
        sessionStartRef={sessionStartRef}
        sessionHistory={sessionHistory}
        show={showSidebar}
        fetchAsset={fetchAsset}
        setCurrentSession={setCurrentSession}
        handleNewChat={handleNewChat}
        currentSession={currentSession}
        setIsImageModalOpen={setIsImageModalOpen}
        setShowSidebar={setShowSidebar}
        handleAdminModal={handleAdminModal}
      />
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        {authStore.settingPaneAccessible && groupService && <AdminModal isOpen={openAdmin} handleAdminModal={handleAdminModal} allMode={authStore.manage_tab_list} />}
        <FilePreviewModalCompoennt open={fileModalStore.isOpen} file={fileModalStore.file_wclean_summary} />
        <ImageModal 
          isOpen={isImageModalOpen} 
          imageSrc="/newToGINII.jpg" 
          onClose={() => {
            setIsImageModalOpen(false)
          }} 
        />
        <div
          style={{
            marginLeft: showSidebar ? "250px" : "0",
            transition: "margin-left 0.3s",
            marginBottom: "10px",
          }}
        >
          <FilterComponent fetchNewFile={fetchNewFile} toggleSidebar={toggleSidebar} handleAdminModal={handleAdminModal} />
          {/* <ModelBar
            activeItem={activeNav}
            onNavClick={(navItem) => setActiveNav(navItem)}
          /> */}
          <div
            style={{
              height: "1px",
              backgroundColor: "lightgrey",
              marginTop: "10px",
              marginLeft: isMobile ? "30px" : "60px",
              marginRight: isMobile ? "30px" : "70px",
            }}
          ></div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "calc(100vh - 90px)",
            // width: showSidebar ? "calc(100vw - 370px)" : "94%",
            padding: isMobile ? "10px" : "30px",
            borderRadius: "10px",
            margin: "0 auto",
            marginLeft: showSidebar ? "280px" : "auto",
          }}
        >
        <MessageArea messages={messages} setMessages={setMessages} dotCount={dotCount} />
        <MessageBar input={input} setInput={setInput} handleSend={handleSend} showSidebar={showSidebar} isBotThinking={isBotThinking} />
        </div>
      </div>

        <ConfirmDialog 
          open={dialogStore.isOpen}
          onClose={dialogStore.handleClose}
          onConfirm={dialogStore.handleConfirm}
          message={dialogStore.message}
        />
    </div>
  );
});

export default MainPage;
