// import { ReloadOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { loadingStore, notiStore } from '../store';
import { ReplayOutlined } from '@mui/icons-material';

export const LoadingComponent = observer(() => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    notiStore.setupNoti(enqueueSnackbar, closeSnackbar);

    // useEffect(() => {}, [loadingStore.isLoading, loadingStore.loadingText]);

    return loadingStore.isLoading ? (
        <div className="data-table-loader">
            <span className="loading-icon">
                <ReplayOutlined spin />
            </span>
            {/* <span className="loading-text">{loadingStore.loadingText}</span> */}
        </div>
    ) : null;
});
