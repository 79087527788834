import MaterialTable from '@material-table/core';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { GroupManageTableAction } from '../../enums/table';
import { GroupType } from '../../enums/group';
import { useEffect, useState } from 'react';
import { adminStore, notiStore } from '../../store';
import { groupService, userService } from '../../service';
import { toJS } from 'mobx';
import { Severity } from '../../enums/severity';
import ConfirmDialog from '../confirmDialog';

const OwnerListEditOptionComponent = (props) => {
  return <>
  <Autocomplete
    multiple
    id="tags-outlined"
    options={adminStore.owner_list}
    getOptionLabel={(option) => option.preferred_username}
    defaultValue={props.value}
    value={props.value}
    isOptionEqualToValue={(option, value) => option.oid === value.oid}
    // defaultValue={props.value.map((admin) => admin.preferred_username) ?? []}
    // value={props.value && props.value != undefined ? props.value.map((admin) => adminStore.owner_list.find((a) => a.oid == admin.oid)) : []} 
    filterSelectedOptions
    onChange={(e, v) => {
        if(v){
          return props.onChange(v);
        }
    }}
    renderInput={(params) => (
      <TextField
        {...params}
      />
    )}
/></>
}

const OwnerListRenderComponent = (rowData) => {
  const admin_names = [...rowData.admin_list.map((admin) => admin.preferred_username.split('@')[0])]
  // const view_list = rowData.admin_list.length > 0 ? rowData.admin_list.map((admin) => admin.preferred_username): []
  return (
    <>
        <Autocomplete
            multiple
            options={adminStore.owner_list}
            // defaultValue={[...admin_names]}
            value={admin_names}
            filterSelectedOptions
            forcePopupIcon={false}
            readOnly
            sx={{
                // border: "1px solid blue",
                '& .MuiOutlinedInput-root': {
                    // border: "1px solid yellow",
                    borderRadius: '0',
                    padding: '0',
                },
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                    border: 'None',
                },
            }}
            renderInput={(params) => <TextField {...params} />}
        />
    </>
  );
}

const GroupManagementTable = ({ }) => {
  const [loading, load] = useState(false)
  const tableColumns = [
    { title: 'Group Name', field: 'name' },
    {
      title: 'Group Admin List',
      field: 'admin_list',
      editComponent: OwnerListEditOptionComponent,
      render: OwnerListRenderComponent,
      customFilterAndSearch: (term, rowData) => {
        return rowData.admin_list && rowData.admin_list.length > 0 ? rowData.admin_list.map(a => a.preferred_username).some(x => x.includes(term)) : false
      },
    },
    {
      title: 'Type',
      field: 'group_type',
      lookup: { 'Private': GroupType.Private, 'Public': GroupType.Public },
      initialEditValue: GroupType.Private,
      editable: 'onAdd',
      width: '100px'
    },
    // {
    //   title: 'Created At',
    //   // field: 'created_at',
    //   width: '10px',
    //   editable: false
    // },
    {
      title: 'Updated',
      field: 'updated_at',
      width: '200px',
      editable: false
    }
    // Add more columns as needed
  ];

  // const tableData = [
  //   { admin_list: ['Toon', 'B'], name: 'SCG', groupType: GroupType.Public},
  //   { admin_list: ['B'], name: 'A', groupType: GroupType.Private},
  //   { admin_list: ['Da'], name: 'B', groupType: GroupType.Private },
  //   // Add more rows as needed
  // ];

  const [tableData, setTableData] = useState([])

  const fetchAllGroup = async () => {
    const response = await groupService.fetchAllGroup()
    console.log(`All Group: ${response}`)
    console.log(response)
    setTableData([...response]);
  }

  const onStart = async () => {
    load(true);
    // setTableData([]);
    await userService.fetchOwnerList()
    await fetchAllGroup()
    setTimeout(() => load(false), 1000);
  }

  const [userGroup, setUserGroup] = useState(null);
  const [userAction, setUserAction] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleConfirmDialog = async () => {
    setIsDialogOpen(false);
    switch (userAction) {
      case GroupManageTableAction.Add:
          await groupService.create(userGroup) 
          break;
      case GroupManageTableAction.Edit:
          await groupService.edit(userGroup.id, userGroup)
          break;
      case GroupManageTableAction.Remove:
          await groupService.remove(userGroup.id)
          break;
    }

    setTimeout(() => load(false), 100);
    await onStart()
  };

  const handleCloseDialog = () => {
      setIsDialogOpen(false);
      load(false);
  };

  const handleAction = async (action, group) => {
    load(true)

    // Admin list will use oid
    group = { ...group, admin_list: group.admin_list.length > 0 ? group.admin_list.map((admin) => toJS(admin).oid): [] }

    if(action == GroupManageTableAction.Add) {
      const is_asset_complete_to_create = group.name && group.group_type && group.admin_list && group.admin_list.length > 0
      if(!is_asset_complete_to_create){
        notiStore.open({ message: 'Please ensure all required information is provided.', hideIn: 4000, severity: Severity.Warning })
        load(false)
        return
      }
    }

    console.log(`Action: ${action}`);
    console.log(group)
    setUserAction(action);
    setUserGroup(group);
    setIsDialogOpen(true);
  };

  useEffect(() => {
    onStart()
  }, [])

  return <div><MaterialTable
    title=""
    columns={tableColumns}
    data={tableData}
    isLoading={loading}
    options={{
      search: true,
      paging: false,
      addRowPosition: 'first',
      filtering: false,
      minBodyHeight: '75vh',
      maxBodyHeight: '75vh',
      exportButton: true,
      padding: 'dense',
      actionsColumnIndex: -1,
      headerStyle: {
        position: 'sticky',
        top: 0,
        background: '#e0e0e0',
        fontWeight: 'bold',
        fontSize: '16px',
        "z-index": '100'
      },
      actionsCellStyle: {
        // display: "flex",
        width: '100px',
        paddingLeft: '30px'
      }
    }}
    editable={{
      onRowUpdate: (rowData) => handleAction(GroupManageTableAction.Edit, rowData),
      onRowAdd: (rowData) => handleAction(GroupManageTableAction.Add, rowData),
      onRowDelete: (rowData) => handleAction(GroupManageTableAction.Remove, rowData)
    }}
  />
  <ConfirmDialog 
                open={isDialogOpen}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmDialog}
                message={'Are you sure to change/delete this group?'}
            />
            </div>
}
export default GroupManagementTable;