import AdminStore from './admin.store';
import AuthStore from './auth.store';
import AxiosStore from './axios.store';
import FilePreviewModalStore from './file_modal';
import GlobalDialogStore from './globalDialog';
import LoadingStore from './loading.store';
import NotiStore from './noti.store';
import ContextStore from './context.store';
import SessionStore from './session.store';

export const axiosStore = new AxiosStore();
export const authStore = new AuthStore();
export const notiStore = new NotiStore();
export const loadingStore = new LoadingStore();
export const sessionStore = new SessionStore()
export const fileModalStore = new FilePreviewModalStore()
export const adminStore = new AdminStore()
export const dialogStore = new GlobalDialogStore()
export const contextStore = new ContextStore()