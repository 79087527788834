import { makeAutoObservable } from 'mobx';
import { Severity } from '../enums/severity';

class GlobalDialogStore {
    isOpen = false;
    handleClose = null
    handleConfirm = null
    message = 'Are you sure ?'


    isPublishSettingChanged = false
    isPresetSettingChanged = false
    isQuotaSettingChanged = false

    constructor() {
        makeAutoObservable(this);
    }

    open(message, handleClose, handleConfirm){
        this.message = message
        this.handleClose = () => {
            handleClose();
            this.isOpen = false;
        }
        this.handleConfirm = () => {
            handleConfirm();
            this.isOpen = false;
        }
        this.isOpen = true
    }

    clear() {
        this.isOpen = false;
        this.handleClose = null;
        this.handleConfirm = null;
        this.message = 'Are you sure ?';
    }

    get presetChanged(){
        return this.isPublishSettingChanged || this.isPresetSettingChanged
    }
}

export default GlobalDialogStore;
