import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { notiStore } from '../store';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

// renderComponent
const Expander = ({ 
  title,
  detail,
  defaultExpanded=true, 
  disabledSave=true,
  onSave=()=>{},
  children, 
  customButtonAsset={ includeButton: false, actionTitle: '', actionOnClick: ()=>{}, disableCustomButton: true}, onExpandAction=()=>{} },
) => {
  const [expanded, setExpanded] = React.useState(defaultExpanded);
  const { includeButton, actionTitle, actionOnClick, disableCustomButton } = customButtonAsset

  const panelId = 'xxxPPP'

  React.useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  React.useEffect(() => {
    console.log(`Disabled save: ${disabledSave}`)
    console.log(disabledSave)
  }, [disabledSave])

  return (
    <div>
        <Accordion expanded={expanded} key={panelId} onChange={() => {
            setExpanded(!expanded);
            onExpandAction(!expanded)
        }}>
        <AccordionSummary aria-controls={`${panelId}-content`} id={`${panelId}-header`}>
        <Typography sx={{ width: '33%', flexShrink: 0 }}>{title}</Typography>
        <Typography sx={{ color: 'text.secondary' }}>
            {detail}
        </Typography>
        {expanded && 
        <div style={{ marginLeft: 'auto'}}>
          {includeButton && <Button disabled={disableCustomButton} variant='outlined' sx={{marginLeft: 'auto', marginRight: '5px', color:'black', padding: '0px', marginTop:'0px'}} onClick={(event) => {
            // notiStore.open({ message: `Trigger ${actionTitle} Successfully` })
            actionOnClick()
            event.stopPropagation();
          }}>{actionTitle}</Button>}
          <Button disabled={disabledSave} variant='outlined' sx={{marginLeft: 'auto', color:'black', padding: '0px', marginTop:'0px'}} onClick={async (event) => {
            event.stopPropagation();
            await onSave()
          }}>          Save          </Button>
        </div>}
        </AccordionSummary>
        <AccordionDetails>
           {children}
        </AccordionDetails>
    </Accordion>
    </div>
  );
}

export default Expander
