import React, { useEffect, useState } from "react";
// import _sampleData from "../db/sampleData";
import TreeSelect, {
  FreeSoloNode,
  DefaultOption,
  getDefaultOptionProps,
} from "mui-tree-select";
import { TextField } from "@mui/material";

export class Node {
  constructor(value) {
    this.value = value;
  }
  getParent(directory) {
    const parent = (() => {
      if ("subDirectories" in this.value) {
        return null;
      } else if ("months" in this.value) {
        return (
          directory.find(({ subDirectories }) =>
            subDirectories.some(({ id }) => id === this.value.id)
          ) || null
        );
      }
      //   else {
      //     for (const { subDirectories } of sampleData) {
      //       const state = subDirectories.find(({ months }) =>
      //         months.some(({ id }) => id === this.value.id)
      //       );
      //       if (state) {
      //         return state;
      //       }
      //     }
      //     return null;
      //   }
    })();
    return parent ? new Node(parent) : parent;
  }
  getChildren() {
    if ("subDirectories" in this.value) {
      return this.value.subDirectories.map((state) => new Node(state));
    } else if ("months" in this.value) {
      return this.value.months.map((city) => new Node(city));
    } else {
      return null;
    }
  }
  isBranch() {
    return "subDirectories" in this.value || "months" in this.value;
  }
  isEqual(to) {
    return to.value.id === this.value.id;
  }
  toString() {
    return this.value.name;
  }
}

const syncOrAsync = function (value, returnAsync) {
  if (returnAsync) {
    return new Promise((resolve) =>
      setTimeout(() => resolve(value), Math.random() * 500)
    );
  }
  return value;
};

const FolderSelector = ({ value, setValue, defaultDirectory }) => {
  const [directoryData, setDirectoryData] = useState(defaultDirectory);
  const [runAsync, setRynAsync] = useState(false);
  const [branch, setBranch] = useState(null);
  return (
    <div style={{ width: 350 }}>
      <TreeSelect
        sx={{ m: 1 }}
        branch={branch}
        onBranchChange={(_, branch) => void setBranch(branch)}
        freeSolo={
          (branch === null || branch === void 0 ? void 0 : branch.value) &&
          false
        }
        getChildren={(node) =>
          syncOrAsync(
            node
              ? node.getChildren()
              : directoryData.map((data) => new Node(data)),
            runAsync
          )
        }
        getParent={(node) => syncOrAsync(node.getParent(defaultDirectory), runAsync)}
        getOptionDisabled={(option) => {
          var _a;
          return (
            option.isBranch() &&
            !((_a = option.getChildren()) === null || _a === void 0
              ? void 0
              : _a.length)
          );
        }}
        isBranch={(node) => syncOrAsync(node.isBranch(), runAsync)}
        isOptionEqualToValue={(option, value) => {
          return option instanceof FreeSoloNode
            ? value instanceof FreeSoloNode &&
                value.toString() === option.toString() &&
                (option.parent === null || value.parent === null
                  ? option.parent === value.parent
                  : option.parent.isEqual(value.parent))
            : value instanceof FreeSoloNode
            ? false
            : option.isEqual(value);
        }}
        // multiple
        renderInput={(params) => (
          <TextField {...params} label="Folder Selector" />
        )}
        renderOption={(...args) => (
          <DefaultOption
            {...((props, node) => {
              var _a, _b;
              return {
                ...props,
                ListItemTextProps: {
                  ...props.ListItemTextProps,
                  secondary:
                    node instanceof FreeSoloNode
                      ? undefined
                      : "subDirectories" in node.value
                      ? undefined
                      //  `subDirectories ${
                        //     ((_a = node.getChildren()) === null || _a === void 0
                        //     ? void 0
                        //     : _a.length) || 0
                        // }`
                      : undefined,
                },
              };
            })(getDefaultOptionProps(...args), args[1])}
          />
        )}
        value={value}
        onChange={(_, value) => {
          setValue(value);
        }}
      />
    </div>
  );
};

export { FolderSelector };
