import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import { Box } from '@mui/system';
import ReactMarkdown from 'react-markdown';
import { configure, toJS } from 'mobx';

import { fileGroupService, fileService } from '../service';
import { fileModalStore, notiStore, sessionStore } from '../store';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";


import { MiMeType } from '../enums/file';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { DeleteForever, HighlightOff } from '@mui/icons-material';

import { isMobile } from 'react-device-detect';

configure({
    enforceActions: 'never',
});


const FilePreviewModalCompoennt = ({ open, file }) => {
    // const [uri, setUri] = useState(null)
    const [docs, setDocs] = useState([])
    const [value, setValue] = useState(0);

    const [onDelete, setOnDelete] = useState(false)

    const handleDelete = async (event) => {
        setOnDelete(true)
        try {
            await fileService.delete_file(file['_id'])
            sessionStore.personal_files = sessionStore.personal_files.filter(item => item['_id'] != file['_id'])
            notiStore.open({ message: 'Delete file successfully', role: 'success' })
        } catch(error){
            console.log(error)
            notiStore.error(error, 'Delete file failed')
        }
        // Wait sleep 3s
        await new Promise(r => setTimeout(r, 300));
        setOnDelete(false)
        fileModalStore.close()
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchPdf = async (uri) => {
        try {
          const response = await axios.get(uri, {
            responseType: 'blob', // Ensure the response is treated as binary data
          });
          return response.data
        } catch (error) {
          console.error('Error fetching PDF:', error);
        }
    };

    const fetch_doc_uri = async (file) => {
        try{
            const file_name = file['name']
            let file_uri = ''
            if(file['file_mode'] == 'group' && file['group_id']) {
                file_uri = await fileGroupService.get_presigned_url(file['_id'])
            } else {
                file_uri = await fileService.get_presigned_url(file['_id'])
            }
            const is_pdf = file_name.endsWith('.pdf')
            const is_txt = file_name.endsWith('.txt')
            const is_csv = file_name.endsWith('.csv')
            if(is_pdf || is_txt || is_csv) {
                const binary = await fetchPdf(file_uri)
                const mime_type = is_pdf ? MiMeType.PDF : is_txt ? MiMeType.TXT : MiMeType.CSV
                const downloadFIle = new File([binary], file_name, { type: mime_type })
                setDocs([
                    { 
                        uri: window.URL.createObjectURL(downloadFIle),
                        fileName: file_name
                    }
                ])
            } else {
                setDocs([
                    { uri: file_uri }
                ])
                // setUri(encodeURI(file_uri))
            }

        } catch(error) {
            notiStore.error(error, 'Cannot get file uri')
            setDocs([])
        }
    }


    useEffect(() => {
        console.log('file ', file)
        if(open && file){
            fetch_doc_uri(file)
        }
    }, [file, open]);

    // Init handle close modal
    const handleClose = () => {
        setDocs([])
        setValue(0)
        fileModalStore.close()
    };

  // Render the content for the active tab
    const tabContent = (index) => {

        switch (index) {
            case 1:
            return (
                file && file.summary && file.summary.length > 0 && 
                <ReactMarkdown components={{
                    a: ({node, ...props}) => <a {...props} target="_blank" rel="noopener noreferrer" />
                    }}>{file.summary}</ReactMarkdown>
            );
            case 0:
            return (
                <DocViewer
                    documents={docs} 
                    pluginRenderers={DocViewerRenderers}
                    // config={{
                    //     disableHeader: true,
                    //     disabledFIleName: true,
                    //     retainURLParams: true,
                    // }}
                />
            );
            default:
            return null;
        }
    };


    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%', left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        height: '80%',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden' // Ensure overflow from children doesn't affect the modal's appearance
                    }}
                >
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                        }}
                    >
                        <Tabs value={value} onChange={handleChange} aria-label="Content Tabs">
                            <Tab label={isMobile ? "File" : "File Content"} />
                            <Tab label={isMobile ? "Summarize" : "Summarize Content"} />
                        </Tabs>
                    </Box>
                    <Box style={{ overflowY: 'scroll', minHeight: '450px' }}>
                        {tabContent(value)}
                    </Box>
                    {/* Position the close button absolutely within the Box */}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 8, // Adjust top and right values as needed for padding
                            right: 8,
                            zIndex: 1, // Ensure it's above other content
                        }}
                    >
                        <Button onClick={handleClose}>
                            CLOSE
                            <CloseIcon />
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>

    );
}

export default FilePreviewModalCompoennt;
