import { makeAutoObservable  } from 'mobx';
import { GroupType } from '../enums/group';

class AdminStore {

    // All possible owner
    owner_list = []


    constructor() {
        makeAutoObservable(this);
    }

    // get owner_list() {
    //     return this.owner_list
    //     // return this.group_admin_list.map((admin) => admin.preferred_username)
    // }
}

export default AdminStore;