// Path: service/file.js
import {axiosStore} from '../store/';

// Create async function get file list

class FileService {

    async get_file_list(){
        const response = await axiosStore.baseAxios.get('/file_management/personal');
        return response.data;
    }

    async fetch_access_files(){
        const response = await axiosStore.baseAxios.get('/file_management/access_list')
        // return response.data
        // Return example {
        //     'personal': list(str)
        //     'public': list(str)
        // }
        return response.data
    }
    
    async upload_file(file, file_type, file_workspace){
    
        const formData = new FormData();
        formData.append('file', file);
        // formData.append('file_type', file_type);
        // formData.append('file_workspace', file_workspace);
    
        const response = await axiosStore.baseAxios.post('/file_management/', formData, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    }
    
    async delete_file (file_id) {
        const response = await axiosStore.baseAxios.delete(`/file_management/${file_id}`);
        return response.data;
    }

    async get_presigned_url(file_id){
        // encodeURI(file_name)
        // const encoded_file_name = encodeURI(file_name)
        const response = await axiosStore.baseAxios.post(`/file_management/get_presigned_url/${file_id}`)
        return response.data.content_source
    }

}

export default FileService