import { adminStore, axiosStore } from "../store";

class GroupUserService {

    async fetchAllUser(group_id, query) {
        // Fetch all group that user can access
        const response = await axiosStore.baseAxios.post(`/group_user/${group_id}/user`,{
            ...query
        })
        return response.data
    }

    async create(group_id, email) {
        await axiosStore.baseAxios.post(`/group_user/${group_id}`, {
            email: email
        })   
    }

    async edit(group_id, group_user_id, email) {
        await axiosStore.baseAxios.put(`/group_user/${group_id}/${group_user_id}`, {
            email: email
        })   
    }

    // Soft delete
    async remove(group_id, group_user_id) {
        await axiosStore.baseAxios.delete(`/group_user/${group_id}/${group_user_id}`)
    }

    // Upload file to bulk insert the user
    async upload_file(file, group_id){
    
        const formData = new FormData();
        formData.append('file', file);
        // formData.append('file_type', file_type);
        // formData.append('file_workspace', file_workspace);
    
        const response = await axiosStore.baseAxios.post(`/group_user/bulk_insert_user/${group_id}`, formData, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    }
    

}

export default GroupUserService