import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import * as microsoftTeams from "@microsoft/teams-js";


// import("@microsoft/teams-js").then((teamModule) => {
//   // Your code that uses the microsoftTeams module goes here
//   microsoftTeams = teamModule
// }).catch((err) => {
//   console.error("Error importing @microsoft/teams-js:", err);
//   microsoftTeams = undefined
// });


if(microsoftTeams){
  microsoftTeams.initialize();
  const root = ReactDOM.createRoot(document.getElementById('root'));
  microsoftTeams.authentication.getAuthToken({
    successCallback: (token) => {
        console.log("Token acquired:", token);
        const ms_token = token.toString();
        root.render(
          <React.StrictMode>
            <App ms_token={ms_token}/>
          </React.StrictMode>
        );
    },
    failureCallback: (error) => {
        console.error("Error getting token:", error);
        alert('fail getting token')
        root.render(
          <React.StrictMode>
            <div>403 Forbidden</div>
          </React.StrictMode>
        );
    }
  });
} else{
  // console.log(error)
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <div>403 Forbidden</div>
    </React.StrictMode>
  );
  // root.render(
  //   <React.StrictMode>
  //     <App ms_token={ms_token} />
  //   </React.StrictMode>
  // );
}

// import { configure } from 'mobx';
// configure({
//   enforceActions: 'never',
// });

// let ms_token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IkwxS2ZLRklfam5YYndXYzIyeFp4dzFzVUhIMCJ9.eyJhdWQiOiIxNjEwM2MzYy1kYTI5LTQ1YzgtYWI5OS0yN2ZkZDZkMGEyMGUiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vNWRiOGJmMGUtODU5Mi00ZWQwLTgyYjItYTZkNGQ3NzkzM2Q0L3YyLjAiLCJpYXQiOjE3MTQ2MjM3NzMsIm5iZiI6MTcxNDYyMzc3MywiZXhwIjoxNzE0NjI4OTIzLCJhaW8iOiJBV1FBbS84V0FBQUEzRnVkWmFZWXgrVTlSOEdrUmNiazNjeVFVNE52MXJ0UVRla3hkZXE4Q2xFdVRySitTMW9qeUFXM2hTbWhMcjMwK3JXTzZXQkVNdnJHT05pbFpVRDNKMXRFK1lDMHZRaXJ3QzFjdVRNdnA2Znk0QTYyQXdKVDBxTzBpb09tajNCVCIsImF6cCI6IjVlM2NlNmMwLTJiMWYtNDI4NS04ZDRiLTc1ZWU3ODc4NzM0NiIsImF6cGFjciI6IjAiLCJuYW1lIjoiTmF0dGFrYW4gUHJhbXVhbnlhdCIsIm9pZCI6IjdmZmFhMjBlLWU1NDMtNGMwZi1iNTNjLTJjMmU5ZjA0Yzg1ZCIsInByZWZlcnJlZF91c2VybmFtZSI6Im5hdHByYW11QHNjZy5jb20iLCJyaCI6IjAuQVZZQURyLTRYWktGMEU2Q3NxYlUxM2t6MUR3OEVCWXAyc2hGcTVrbl9kYlFvZzVXQUg0LiIsInNjcCI6InVzZXJfaW1wZXJzb25hdGlvbiIsInN1YiI6IkRoQkY5LVJWZmZUdEZhN25BOWU2bzkyaGFJZFFINFR5X0JablpIRUNNWFkiLCJ0aWQiOiI1ZGI4YmYwZS04NTkyLTRlZDAtODJiMi1hNmQ0ZDc3OTMzZDQiLCJ1dGkiOiJsTGlxREZNeWJVT2Z3RDl2NWZnWkFBIiwidmVyIjoiMi4wIn0.f2PPNMS3c_9GF2jjtW3JjvCfcsZ_CkcRCzAdMe8zdZNbW_15Zb7Z-sPjSwFrCsFWhEKbMuLxnqkZuPf_2E3bDpyDNYFk1NTUJ77PbEdwnV5eYrM2VoVYrcU4U3nemWAdgt31AxuDCXFnKK3DyhhGVbCbIugrU1j9JWQrxAVUogx1o8_Ski21duT5KkzZujSTek-t4KcnzeSPtPMW6EHGUX_TjYcKtHxAPHjCA1itqEx_DbT7DMS9vhAoqhX0VR1SVA8kcdaXI9DXUln0EXb_aSdgEmiekWNXS90gPq_oqJ3e6b7gsb3OKzshMD7uQ7LPjngmky9IWeotZKfx0SFAVw"
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App ms_token={ms_token} />
//   </React.StrictMode>
// );



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
