// Path: service/file.js
import {axiosStore} from '../store/';
import { AssetType } from "../enums/manage";
import { fileGroupService } from '.';

// Create async function get file list



class AssetService {

    async get_asset_list(group_id){
        const response = await axiosStore.baseAxios.get(`/asset/${group_id}`);
        let data = response.data.map(item => {
            return {...item, id: item['_id']}
        });
        if(data.length > 0) {
            const fileData = await fileGroupService.fetch_group_access_files(group_id)
            data = [...data, ...fileData.map(obj => {
                return {
                    asset_type: AssetType.File,
                    created_at: obj.created_at,
                    updated_at: obj.updated_at,
                    group_id: obj.group_id,
                    id: obj['_id'],
                    name: obj.name,
                    parent_id: obj.parent_id,
                    status: obj.status
                }
            })]
    
            const idToItemMap = data.reduce((map, item) => {
                map[item.id] = item;
                return map;
            }, {});
    
            const findLevel = (item, idToItemMap) => {
                if (item == undefined || item.parent_id === null) {
                    return 0;
                } else {
                    const parent = idToItemMap[item.parent_id];
                    return 1 + findLevel(parent, idToItemMap);
                }
            };
            console.log('==========')
            console.log(data)
            return data.map(item => {
                return {...item, level: findLevel(item, idToItemMap)}
            });
        } else {
            return []
        }
    }

    async create(group_id, name, asset_type, parent_id){
        await axiosStore.baseAxios.post('/asset/', {
            "name": name,
            "asset_type": asset_type,
            "group_id": group_id,
            "parent_id": parent_id
        })
    }

    async update(asset_id, name){
        const response = await axiosStore.baseAxios.put(`/asset/${asset_id}`, { name });
        return response.data;
    }
    
    async delete (asset_id) {
        const response = await axiosStore.baseAxios.delete(`/asset/${asset_id}`);
        return response.data;
    }

}

export default AssetService