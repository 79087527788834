import { adminStore, axiosStore } from "../store";

class UserService {
    async me() {
        const response = await axiosStore.baseAxios.get('/user/me');
        return response.data;
    }

    async fetchAllUsers(query) {
        // Call on /admin/fetch-user
        const response = await axiosStore.baseAxios.post('/admin/fetch-user', {
            ...query
        })

        return response.data
    }

    async changeRole(user_oid, role) {
        const response = await axiosStore.baseAxios.patch(`/admin/user/${user_oid}/change_role`, {
            role: role
        })
        return response.data
    }


    async fetchOwnerList() {
        const respone  = await axiosStore.baseAxios.get('/admin/fetch-owner-list')
        adminStore.owner_list = respone.data
    }

    // async getAdminList() {
    //     const response = await axiosStore.baseAxios.get('/admin/fetch-admin-list')
    //     return response.data
    // }

}

export default UserService