// import { sessionService } from ".";
import { toJS } from "mobx";
import { FileMode } from "../enums/file";
import { axiosStore, sessionStore } from "../store";

class QAService {

    async ask(message, session_id){
        if (sessionStore.use_personal && ( sessionStore.currentFile['name'] == '' || sessionStore.currentFile['file_type'] != FileMode.Personal )) {
            throw new Error('Please select personal file to ask question');
        }

        console.log('================== LOGGING file to ask =========================')
        console.log(toJS(sessionStore.selectedGroupIdsToFileIds))
        console.log(toJS(sessionStore.selectedPersonalFileIds))
        const use_file_mode = sessionStore.compute_file_mode

        // TODO: Determine group id from sessioon
        const data = {
            session_id: session_id,
            user_message: message,
            file_mode: use_file_mode,
            personal_file_ids: toJS(sessionStore.selectedPersonalFileIds),
            group_id_to_file_ids: toJS(sessionStore.selectedGroupIdsToFileIds),
            model_type: sessionStore.model_type
        }
        console.log(data)

        const response = await axiosStore.baseAxios
        .post('/qa/ask', data)
        .then(response => {
            return {"qa_response": {
                "id": response.data['id'],
                "response_message": response.data['response'],
                "response_type": response.data['response_type'],
                "references": response.data['response_references']
            }};
        })
        
        return response;
    }

    async update_sentiment(msg_id, sentiment){
        const data = {
            sentiment: sentiment
        }
        const response = await axiosStore.baseAxios
        .patch(`/qa/chat/${msg_id}/sentiment`, data)
        .then(response => {
            return response.data;
        })
        
        return response;
    }
    
    async ask_v1(message){
        // let config = {
        //     method: 'get',
        //     url: `https://ginii-dev-db.jumpai.tech/qa/ask?message=${encodeURIComponent(input)}`,
        //     headers: {
        //         'accept': 'application/json',
        //         'Authorization': 'Bearer jK9pL5qRmN8sT2xU7zE4'
        //     }
        // };

        const response = await axiosStore.baseAxios.get(`/qa/ask?message=${encodeURIComponent(message)}`);
        return response.data;
    }
}

export default QAService