import { Box, Divider, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField, TextareaAutosize, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DropdownX from '../dropdown';
import Expander from '../expander';
import { defaultPromptFormat } from '../../constant/prompt_example';
import GroupSelect from '../groupSelect';
import { ModelDisplay, ModelOption, PromptConfigMode, PromptConfigModeDisplay, PromptVersion, PromptVersionDisplay, ResponseStyle, RetrievalFilter } from '../../enums/prompt';
import { ContentCopyOutlined, RestoreOutlined } from '@mui/icons-material';
import { fileGroupService, presetService, quotaService } from '../../service';
import { dialogStore, notiStore, contextStore, authStore } from '../../store';
import { observer } from 'mobx-react-lite';
import QuotaService from '../../service/quota';
function deepEqual(obj1, obj2) {
  if (obj1 === obj2) return true;
  
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
      return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
          return false;
      }
  }

  return true;
}

// import Textarea from '@mui/joy/Textarea';

const SettingsComponent = observer(({ group_list }) => {
  const getDefaultPrompt = (mode) => {
    return mode != PromptConfigMode.General ? contextStore.filePromptFormat : contextStore.generalPromptFormat
  }
  
  const getDefaultPresetSetting = (mode, model_type) => {
    return {
      description: 'prompt พื้นฐานใช้ในการตอบคำถามไฟล์ทั่วไป',
      base: {
        model_option: model_type,
        response_style: ResponseStyle.Creative,
        prompt_format: getDefaultPrompt(mode)
      },
      retrieval_filter: RetrievalFilter.High,
    }
  }

  const [currentGroup, setGroup] = useState(group_list[0])
  const [defaultPublishSetting, setDefaultPublishSetting] = useState(null)
  const [defaultPresetSetting, setDefaultPresetSetting] = useState(null)
  const [publishSetting, setPublishSetting] = useState({
      published_version: Object.values(PromptVersion)[0],
      test_version: Object.values(PromptVersion)[2],
      model_option: ModelOption.AzureOpenAI_GPT_4_O
  })

  const [currentFileStorageUsed, setCurrentStorage] = useState(10)

  const [selectedPresetVersion, setPresetVersion] = useState(PromptVersion.Version1)
  const [mode, setMode] = useState(PromptConfigMode.GroupFile)
  const [presetSetting, setPresetSetting] = useState(getDefaultPresetSetting(mode, ModelOption.AzureOpenAI_GPT_4_O))
  const [isQuotaExpanded, expandQuota] = useState(false)

  const [defaultQuotaSetting, setDefaultQuotaSetting] = useState(null)
  const [quotaSetting, setQuotaSetting] = useState(null);

  // const [currentLimit, setCurrentLimit] = useState(10)
  const handleCurrentStorageLimitChange = (event) => {
    const value = event.target.value
    // if(value >= defaultQuotaSetting.storage_size_limit) {
    //   setQuotaSetting({ ...quotaSetting, storage_size_limit: value})
    // }
    setQuotaSetting({ ...quotaSetting, storage_size_limit: value})
  };

  // Reset prompt control part to default mode
  const onResetClick = () => {
    // Restore setting only prompt control part
    const model_type = mode === PromptConfigMode.GroupFile ? ModelOption.AzureOpenAI_GPT_4_O : publishSetting.model_option;
    setPresetSetting({ ...presetSetting, ...getDefaultPresetSetting(mode, model_type)})
  }

  const renderHeader = () => {
    return (<div style={{ fontSize: '20px', marginBottom: '5px' }}>
      <Stack direction='row'>
        {mode === PromptConfigMode.GroupFile ? (<>
          <div style={{ marginTop: '8px'}}>Group {currentGroup.name}</div>
          <GroupSelect
                selectGroup={setGroup}
                groupList={group_list}
                defaultGroup={group_list[0]}
            />
        </>): <>
          <DropdownX options={Object.values(ModelOption)} optionLabels={Object.values(ModelDisplay)} label='Model Selection' value={publishSetting.model_option} sx={{ padding: '0px', width: '35%', marginTop: '0px', marginBottom: '0px' }} small osx={{ height: '38px'}} onChange={(value) => {
            // alert(`${value} changed`)
            setPublishSetting({
              ...publishSetting,
              model_option: value
            })
            setPresetSetting({
              base: {
                ...presetSetting.base,
                model_option: value
              }
            })
          }}/>
        </>}
        {authStore.isAdmin && <DropdownX options={Object.values(PromptConfigMode)} optionLabels={Object.values(PromptConfigModeDisplay)} onChange={setMode} label='Mode Selection' value={mode} sx={{ padding: '0px', width: '35%', marginTop: '0px', marginBottom: '0px', marginLeft: 'auto'}} small osx={{ height: '38px'}} />} 
      </Stack>
    </div>)
  }

  const renderPresetDropdowns = () => {
    const presetVersionDropdown = (width='49%') => (
      <Box style={{width: width}}>
        <DropdownX label="Preset version" options={Object.values(PromptVersion)} optionLabels={Object.values(PromptVersionDisplay)} value={selectedPresetVersion} onChange={setPresetVersion}/>
      </Box>
    );

    const presetDescriptionField = (width='49%') => (
      <Box style={{width: width, marginTop: '10px'}}>
        <TextField 
          label="Preset description" 
          placeholder="Preset description" 
          value={presetSetting.description} 
          sx={{ width: '100%'}} 
          size='medium' 
          onChange={(event) => setPresetSetting({...presetSetting, description: event.target.value})}
        />
      </Box>
    );

    const responseStyleDropdown = (width='49%') => (
      <Box style={{width: width}}>
        <DropdownX 
          label="Response style" 
          options={Object.values(ResponseStyle)} 
          value={presetSetting.base.response_style} 
          onChange={(value) => setPresetSetting({...presetSetting, base: {...presetSetting.base, response_style: value}})}
        />
      </Box>
    );

    const retrievalFilterDropdown = (width='49%') => (
      <Box style={{width: width}}>
        <DropdownX 
          label="Retrieval Filter Threshold" 
          options={Object.values(RetrievalFilter)} 
          value={presetSetting.retrieval_filter} 
          onChange={(value) => setPresetSetting({...presetSetting, retrieval_filter: value})}
        />
      </Box>
    );

    const modelOptionDropdown = (width='32%') => (
      <Box style={{width: width}}>
        <DropdownX 
          label="Model option" 
          options={Object.values(ModelOption)} 
          optionLabels={Object.values(ModelDisplay)}
          value={presetSetting.base.model_option ?? ''} 
          onChange={(value) => setPresetSetting({...presetSetting, base: {...presetSetting.base, model_option: value}})}
        />
      </Box>
    );

    switch(mode) {
      case PromptConfigMode.General:
        return (
          <Stack direction='row' sx={{ justifyContent: 'space-between'}}>
            {presetVersionDropdown('32%')}
            {presetDescriptionField('32%')}
            {responseStyleDropdown('32%')}
          </Stack>
        );
      case PromptConfigMode.GroupFile:
        return (
          <>
            <Stack direction='row' sx={{ justifyContent: 'space-between'}}>
              {presetVersionDropdown('49%')}
              {presetDescriptionField('49%')}
            </Stack>
            <Stack direction='row' sx={{ justifyContent: 'space-between' }}>
              {modelOptionDropdown('32%')}
              {responseStyleDropdown('32%')}
              {retrievalFilterDropdown('32%')}
            </Stack>
          </>
        );
      case PromptConfigMode.PersonalFile:
        return (
          <>
            <Stack direction='row' sx={{ justifyContent: 'space-between'}}>
              {presetVersionDropdown('49%')}
              {presetDescriptionField('49%')}
            </Stack>
            <Stack direction='row' sx={{ justifyContent: 'space-between' }}>
              {responseStyleDropdown('49%')}
              {retrievalFilterDropdown('49%')}
            </Stack>
          </>
        );
      default:
        return null;
    }
  }

  const renderQuotaComponent = () => {
    switch(mode) {
      case PromptConfigMode.PersonalFile:
        return <Stack direction='row' sx={{ display: 'flex', 'justifyContent': 'space-between'}}>
              <Box style={{width: '49%', marginTop: '10px'}}>
              <TextField 
                label="# Max file number" 
                placeholder=""
                value={quotaSetting.max_file_number ?? 10} 
                sx={{ width: '100%'}} 
                size='medium' 
                onChange={(event) => setQuotaSetting({...quotaSetting, max_file_number: event.target.value})}
              />
            </Box><Box style={{width: '49%', marginTop: '10px'}}>
              <TextField 
                label="File Size Limit ( MB )" 
                placeholder="10 MB" 
                value={quotaSetting.file_size_limit ?? 10} 
                sx={{ width: '100%'}} 
                size='medium' 
                onChange={(event) => setQuotaSetting({...quotaSetting, file_size_limit: event.target.value})}
              />
            </Box>
          </Stack>
      case PromptConfigMode.GroupFile:
        return <Stack direction='row' sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Box width='34%'>
          <FormControl fullWidth sx={{ marginTop: '10px'}} size='medium'>
              <InputLabel htmlFor="outlined-adornment-amount-xx" shrink focused>Storage Limit</InputLabel>
              <OutlinedInput
                onChange={handleCurrentStorageLimitChange}
                // defaultValue={quotaSetting.storage_size_limit ?? 0}
                value={quotaSetting.storage_size_limit}
                type={'number'}
                inputProps={{ min: defaultQuotaSetting.storage_size_limit, shrink: true }}
                id="outlined-adornment-amount"
                endAdornment={<InputAdornment position="end">GB</InputAdornment>}
                label="File Storage"
                // error={currentFileStorageUsed >= quotaSetting.storage_size_limit }
              />
            <FormHelperText id="outlined-file-size-helper-text">Current file storage is {currentFileStorageUsed} MB</FormHelperText>
          </FormControl>
        </Box>
        {/* <Box width='30%'>
          <DropdownX 
            label="TPM Quota API ( Token per minute )"
            options={['10K', '50K', '100K']}
            value={presetSetting.quota.api_token_limit} 
            />
        </Box> */}
      </Stack>
      default:
        return <></>

    }
  }

  // Set preset version will be acted before then this function will be triggered later
  const fetchPreset = async (preset_version) => {
    let data = null
    if(mode === PromptConfigMode.GroupFile) {
      // Fetch group presetSetting
      const response = await presetService.getPresetByGroupId(currentGroup.id, preset_version)
      data = response
    }
    else {
      console.log('>>> publish setting <<<')
      console.log(publishSetting)
      // Fetch personal presetSetting
      const response = await presetService.getModelByTypeAndMode(
        publishSetting.model_option ?? ModelOption.AzureOpenAI_GPT_4_O,
        mode,
        preset_version
      )
      // alert(`get response`)
      data = response
    }
    console.log('Fetch preset and publish setting')
    console.log(data)
    if(data){
      const preset = data.preset
      const { group_id, ...obj } = data.publish_value
      setPublishSetting(obj);
      setDefaultPublishSetting(obj)
      setPresetSetting(preset)
      setDefaultPresetSetting(preset)
    }
    return data
  }

  const onFetchPreset = async () => {
    if(!contextStore.is_default_prompt_exist){
      await presetService.getDefaultPrompt()
    }
    await fetchPreset(selectedPresetVersion)
  }

  const onFetchQuota = async (mode, group_id) => {
    let quota = null
    if(mode != PromptConfigMode.General){
      if(mode == PromptConfigMode.GroupFile) {
        const fileUsageResponse = await fileGroupService.calculateFileUsage(group_id)
        setCurrentStorage(parseFloat(fileUsageResponse['total_file_usage']).toFixed(2))
        quota = await quotaService.getQuotaByGroupId(group_id)
      } else {
        quota = await quotaService.getPersonalQuota()
      }
      setDefaultQuotaSetting({ ...quota.quota, "_id": quota['_id']})
      setQuotaSetting({ ...quota.quota, "_id": quota['_id']})
    }
  }

  useEffect(() => {
    // TODO: Check whether there is still change to take action left
    if(selectedPresetVersion){
      onFetchPreset()
    }
    if(currentGroup && currentGroup.id && mode){
      onFetchQuota(mode, currentGroup.id)
    }
    // if(mode && mode != PromptConfigMode.General){
    //   setDefaultQuotaSetting(getDefaultQuotaValue(mode))
    // } else {
    //   setDefaultQuotaSetting(null)
    // }
  }, [mode, currentGroup.id, publishSetting.model_option, selectedPresetVersion]);

  useEffect(() => {
    const isPresetSettingChanged = !deepEqual(presetSetting, defaultPresetSetting);
    dialogStore.isPresetSettingChanged = isPresetSettingChanged
    console.log('Preset Setting Changed:', isPresetSettingChanged);
  }, [presetSetting, defaultPresetSetting, selectedPresetVersion])

  useEffect(() => {
    const isPublishSettingChanged = !deepEqual(publishSetting, defaultPublishSetting);
    dialogStore.isPublishSettingChanged = isPublishSettingChanged
    console.log('Publish Setting Changed:', isPublishSettingChanged);
  }, [publishSetting, defaultPublishSetting])


  useEffect(() => {
    if(PromptConfigMode.GroupFile && quotaSetting && quotaSetting.storage_size_limit <= defaultQuotaSetting.storage_size_limit){
      dialogStore.isQuotaSettingChanged = false
      return
    }
    const isQuotaSettingChanged = !deepEqual(quotaSetting, defaultQuotaSetting);
    console.log('Compare')
    console.log(quotaSetting)
    console.log(defaultQuotaSetting)
    console.log('===================')
    dialogStore.isQuotaSettingChanged = isQuotaSettingChanged
    console.log('quota Setting Changed:', isQuotaSettingChanged);
  }, [quotaSetting, defaultQuotaSetting])

  // useEffect(() => {
  //   return () => {
  //     if(isPresetSettingChanged || isPublishSettingChanged) {
  //       const onClose = () => {}
  //       const onConfirm = () => {}
  //       dialogStore.open('Are you sure to exit, change is still not applied ?', onClose, onConfirm)
  //     }
  //   }
  // }, [])

  const renderPreset = () => {
    return <>
      {renderPresetDropdowns()}

      <div style={{ marginBottom: '10px', marginTop: '1px', display: 'flex', alignItems: 'center'}}>
        Prompt Format 
        <Tooltip title={'Restore prompt'} arrow>
          <IconButton style={{ padding: '0', margin: '0', marginLeft: '5px'}} onClick={() => {
            const defaultPrompt = getDefaultPrompt(mode);
            setPresetSetting({...presetSetting, base: { ...presetSetting.base, prompt_format: defaultPrompt }})
          }}>
            <RestoreOutlined fontSize="small" sx={{fontSize: 20}}/>
          </IconButton>
        </Tooltip>
        <Tooltip title={'Copy content'} arrow>
          <IconButton style={{ padding: '0', margin: '0', marginLeft: '5px'}} onClick={() => {
            navigator.clipboard.writeText(presetSetting.base.prompt_format)
          }}>
            <ContentCopyOutlined fontSize="small" sx={{ fontSize: 18, marginLeft: '5px'}} />
          </IconButton>
        </Tooltip>

      </div>
      <TextareaAutosize
        id='PromptID000' 
        // defaultValue={presetSetting.base.prompt_format} 
        maxRows={15}
        style={{ width: '100%', padding: '10px', backgroundColor: 'darkslategrey', color:'white', overflowY: 'scroll' }}
        minRows={7}
        value={presetSetting.base.prompt_format ?? ''}
        onChange={(e) => setPresetSetting({ ...presetSetting, base: { ...presetSetting.base, prompt_format: e.target.value } })}
      />
      </>
  }

  const renderPresetSelection = () => {
    return <>
    <Stack direction='row' sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box width='49%' small>
            <DropdownX options={Object.values(PromptVersion)} optionLabels={Object.values(PromptVersionDisplay)} value={publishSetting.published_version} label='Publish Version' onChange={(value) => setPublishSetting({...publishSetting, published_version: value})} />
        </Box>
        <Box width='49%' small>
            <DropdownX options={Object.values(PromptVersion)} optionLabels={Object.values(PromptVersionDisplay)} value={publishSetting.test_version} label='Test Version' onChange={(value) => setPublishSetting({...publishSetting, test_version: value})} />
        </Box>
    </Stack>
    </>
  }

  return (
    <div>
    {/* <DropdownX label='Group Selection' options={['Group 1', 'Group 2', 'Group 3']} value={presetSetting.groupSelection} sx={{
        marginLeft: 'auto', width: '300px'
    }}/> */}
      {renderHeader()}
    
      <Expander
        title='Publish Selection'
        detail='เลือก Preset สำหรับใช้งาน'
        disabledSave={!dialogStore.isPublishSettingChanged}
        onSave={async () => {
          dialogStore.open("Are you sure save publish setting ?", () => {}, async () => {
              console.log(`Update publish control with ${mode}`)    
              await presetService.updatePublishControl(mode, publishSetting._id, publishSetting)
               setDefaultPublishSetting({
                ...publishSetting
               })
              notiStore.open({ message: `Save publish control Successfully` })
          })
          
        }}
      >
        {renderPresetSelection()}
      </Expander>
      <Expander
        title='Preset Control'
        detail='ตั้งค่าการใช้งาน Preset'
        defaultExpanded={!isQuotaExpanded}
        disabledSave={!dialogStore.isPresetSettingChanged} 
        customButtonAsset={{ includeButton: true, actionTitle: 'Reset', actionOnClick: onResetClick }}
        onSave={async () => {
          dialogStore.open("Are you sure save preset setting ?", () => {}, async () => {
            await presetService.updatePreset(presetSetting['_id'], presetSetting)
            notiStore.open({ message: `Save preset Successfully` })
            setDefaultPresetSetting({...presetSetting})
          })
          
        }}
        >{renderPreset()}</Expander>
      {mode != PromptConfigMode.General && 
      <Expander
        title='Quota Control'
        detail='ปรับ quota การใช้งาน'
        defaultExpanded={isQuotaExpanded}
        forceExpand
        onExpandAction={expandQuota}
        disabledSave={!dialogStore.isQuotaSettingChanged} 
        onSave={async () => {
          dialogStore.open("Are you sure save quota setting ?", () => {}, async () => {
            await quotaService.update(quotaSetting['_id'], quotaSetting)
            notiStore.open({ message: `Save Quota Successfully` })
            setDefaultQuotaSetting({...quotaSetting})
          })
        }}
        >{quotaSetting != null && renderQuotaComponent()}
        </Expander>}
      <Divider />
    </div>
  );
});

export default SettingsComponent;

