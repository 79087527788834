import axios from 'axios';
import { makeAutoObservable  } from 'mobx';

const get_base_axios = (jwt) => {
    const base_url = process.env.REACT_APP_API_URL
    // const base_url = "https://ginii-app-vdb.azurewebsites.net"
    // const base_url = "https://ginii-app-vdb-dev.azurewebsites.net"
    // const base_url = 'http://localhost:8100'
    
    
    if(jwt){
        return axios.create({
            baseURL: base_url,
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${jwt}`
            }
        });
    } else {
        return axios.create({
            baseURL: base_url,
            headers: {
                'accept': 'application/json',
            }
        });
    }
}

class AxiosStore {
    access_token = null
    
    constructor() {
        makeAutoObservable(this);

        // this.authAxios = get_base_axios('jK9pL5qRmN8sT2xU7zE4')
        this.baseAxios = get_base_axios()
    }

    setBaseAccessToken(accessToken) {
        console.log('Access token set', accessToken)
        this.access_token = accessToken
        this.baseAxios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }

    setInterceptor(func){
        this.baseAxios.interceptors.request.use(async (config) => {
            await func()
            return config;
        
        }, function (error) {
            // Do something with response error
            return Promise.reject(error);
        });
    }
}

export default AxiosStore;