import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function DropdownX({ options, optionLabels=[], label='label', id, value, onChange, sx, osx }) {
  const [selectedValue, setSelectedValue] = React.useState(value || '');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  React.useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <Box sx={{ minWidth: 120, marginTop: '10px', marginBottom: '10px', ...sx }}>
      <FormControl fullWidth size='medium'>
        <InputLabel id={`${id}-label`}>{label}</InputLabel>
        <Select
          sx={{...osx }}
          labelId={`${id}-label`}
          value={selectedValue}
          label={label}
          onChange={handleChange}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option}>{optionLabels.length > 0 ? optionLabels[index]: option}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
