// Path: service/file.js
import { AssetType } from '../enums/manage';
import {axiosStore} from '../store/';

// Create async function get file list

class FileGroupService {

    async fetch_group_access_files(group_id){
        const response = await axiosStore.baseAxios.get(`/user/access_files/${group_id}`)
        return response.data
    }

    async fetch_all_group_access_files(){
        const response = await axiosStore.baseAxios.get(`/user/access_files?fetch_assets=true`)
        return response.data.map(obj => {
            if(obj.asset_type) {
                return obj
            }
            return { ...obj, asset_type: AssetType.File }
        })
    }
    
    async upload_file(file, group_id, parent_id){
        const formData = new FormData();
        formData.append('file', file);
    
        const response = await axiosStore.baseAxios.put(`/file_management_group/upload/${group_id}/${parent_id}`, formData, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    }
    
    async delete_file (file_id) {
        const response = await axiosStore.baseAxios.delete(`/file_management_group/${file_id}`);
        return response.data;
    }

    async get_presigned_url(file_id){
        // encodeURI(file_name)
        // const encoded_file_name = encodeURI(file_name)
        const response = await axiosStore.baseAxios.get(`/user/file_management_group/get_presigned_url/${file_id}`)
        return response.data.content_source
    }

    async calculateFileUsage(group_id){
        const response = await axiosStore.baseAxios.get(`/file_management_group/calculate_file_usage/${group_id}`);
        return response.data
    }

}

export default FileGroupService