class ContextStore {

    generalPromptFormat = null
    filePromptFormat = null

    setDefaultPromptFormat(promptFormat){
        this.generalPromptFormat = promptFormat.general
        this.filePromptFormat = promptFormat.file
    }

    get is_default_prompt_exist(){
        return !!this.generalPromptFormat && !!this.filePromptFormat
    }

}

export default ContextStore;
