// import { v4 as uuidv4 } from 'uuid';
import { axiosStore, sessionStore } from '../store';
import { MessageRole, MessageSentiment } from '../enums/message';
import { FileMode } from '../enums/file';
import SessionStore from '../store/session.store';
import { toJS } from 'mobx';

class SessionService {

    async get_all_sessions() {
         const response = await axiosStore.baseAxios.get(`session/`);

        return response.data.sessions.map((session) => {
            return { 
                ...session,
                title: session.topic && session.topic !== '' ? session.topic : 'New Page ...' 
            }
        });
    }

    // get session by session_id
    // query: session_id
    async get_session_by_id(session_id) {
        const response = await axiosStore.baseAxios.get(`session/${session_id}`);
        return response.data;
    }
    
    // async sync_session_id(session_id) {
    //     await axiosStore.baseAxios.patch(`session/${session_id}`, {
    //         personal_files: sessionStore.personal_files,
    //         use_share: sessionStore.use_shared
    //     });
    // }
    

    async delete_session_by_id(session_id) {
        const response = await axiosStore.baseAxios.delete(`session/${session_id}`);
        return response.data;
    }

    async create_session(user_message){
        console.log('Use', sessionStore.currentFile)

        const use_file_mode = sessionStore.compute_file_mode
        // TODO: Determine group id for backend
        const data = {
            personal_file_ids: toJS(sessionStore.selectedPersonalFileIds),
            group_id_to_file_ids: toJS(sessionStore.selectedGroupIdsToFileIds),
            file_mode: use_file_mode,
            user_message: user_message,
            model_type: sessionStore.model_type
        }

        let response = await axiosStore.baseAxios.post('session', data);

        // Expected response
        // {
        //     "session": {
        //       "user_owner_id": "test001",
        //       "session_id": "81a8c074-44aa-4437-9c37-a110f82b5e42",
        //       "session_type": "base",
        //       "expired": false,
        //       "use_share": true,
        //       "personal_files": [],
        //       "updated_at": "2023-10-05T19:44:33.620954",
        //       "created_at": "2023-10-05T19:44:33.620957",
        //       "session_topic": "",
        //       "first_message_ask": "What's ur race?",
        //       "_id": "651f145e2eb6432cb3577999"
        //     },
        //     "qa_response": {
        //       "response_message": "I'm sorry, but I am unable to answer this question as it is not related to the given SCG documentation.",
        //       "response_source": [],
        //       "response_type": "markdown",
        //       "service": "QnA"
        //     }
        //   }
        
        console.log('response from create session', response)
        response.data.qa_response['references'] = response.data.qa_response['response_references']
        return response.data
    }


    // Get chat history
    // query: session_id

    // {
    //     "session_id": "sessionid@001",
    //     "message": " Hi Gini",
    //     "message_type": "text",
    //     "created_at": "2023-10-03T13:23:29.444000"
    // }

    // {
    //     "role": "user",
    //     "content": " Hi Gini"
    // }
    
    async get_chat_history(session_id) {
        const response = await axiosStore.baseAxios.get(`/session/chat/${session_id}`);
        
        // This will change since we had {req, res} in one object
        // Backend need to filter unsuccess chatout

        // Convert message timestamp to Thai time
        // response.data.map((message) => {
        //     message.created_at = new Date(message.created_at).toLocaleString('en-US', { timeZone: 'Asia/Bangkok' })  
        // })
        return response.data.map((message) => {
            const refs = message.references && message.references.length > 0 ? message.references.map(ref => ref.split('/')[ref.split('/').length-1]).filter(ref => !ref.includes('_summary.txt')) : []
            let body = {
                role: message.entity === MessageRole.Bot ? MessageRole.Bot : MessageRole.User,
                content: message.message,
                // timestamp: new Date(message.created_at),
                timestamp: message.created_at,
                type: message.message_type,
                references: refs
            }
            if(message.entity === MessageRole.Bot){
                // body['sentiment'] = MessageSentiment.Neutral
                body['sentiment']  = message.sentiment
                body['id'] = message.id
            }
            return body
        })
    }
}

export default SessionService




