// import InputLabel from '@mui/material/InputLabel';
import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import WorkspacesIcon from '@mui/icons-material/Workspaces';

export const GroupSelect = ({ selectGroup, groupList }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (group) => {
    selectGroup(group);
    handleClose();
  };

  // console.log(`Group List: ${groupList}`)
  // console.log(groupList)
  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <WorkspacesIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
         {groupList.length > 0 && groupList.map((group, index) => {
          return <MenuItem 
          key={index} value={group} 
          onClick={() => handleMenuItemClick(group)}>
           {group.name}
            </MenuItem>
        })}
      </Menu>
    </div>
  );
}

export default GroupSelect