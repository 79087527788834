import React, { useState, useRef } from 'react';
import { observer } from "mobx-react-lite";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Chip } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { loadingStore, sessionStore } from "../store";
import TextField from '@mui/material/TextField';

export const MessageBar = observer(
  ({ input, setInput, handleSend, showSidebar, isBotThinking }) => {
    const [rows, setRows] = useState(1);
    const textFieldRef = useRef(null);
    const maxRows = 7;

    const handleInputChange = (e) => {
      const value = e.target.value;
      setInput(value);

      const textFieldWidth = textFieldRef.current.offsetWidth;
  
      // Estimate the average number of characters that fit in a line
      const charsPerLine = textFieldWidth / 7.89; // This is an estimate, adjust based on your UI
  
      // Count explicit line breaks
      const explicitLineBreaks = (value.match(/\n/g) || []).length;
  
      // Estimate additional line breaks due to wrapping
      const estimatedWrappedLines = Math.ceil(value.length / charsPerLine);
  
      // Calculate total rows (explicit + estimated)
      const totalRows = explicitLineBreaks + estimatedWrappedLines;
  
      // Update rows, ensuring it doesn't exceed maxRows
      setRows(Math.min(totalRows, maxRows));
  };

  const handleMsgSent = async (e) => {
    await handleSend();
    // handleInputChange(e);
    setRows(1);
  }
    
    return (
      <Card style={{ marginTop: "10px", padding: "10px", width: "100%", border: "0px", boxShadow: "none" }}>
        <div>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            <div style={{ color: "#016D77" }}>Files in use:</div>
            {sessionStore.selectedFileDisplay.map((fileDisplay, index) => (
              <Chip
                key={index}
                style={{color: "black", boxShadow: "2px 1px 3px rgba(0, 0, 0, 0.3)" }}
                size="small"
                variant="outlined"
                label={fileDisplay.title}
                onDelete={() => {
                  sessionStore.unSelectFileV2(fileDisplay.type, fileDisplay.groupId);
                }}
                deleteIcon={
                  sessionStore.someFileSelected ? (
                    <CloseIcon style={{ color: "red" }} />
                  ) : (
                    <></>
                  )
                }
              />
            ))}
          </Box>
          <div style={{
            display: "flex",
            alignItems: "flex-end",
          }}>
             <TextField
                ref={textFieldRef}
                value={input}
                onChange={handleInputChange}
                disabled={loadingStore.fileUploading}
                onKeyPress={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                        if (input.trim() !== "" && !loadingStore.fileUploading && !isBotThinking) {
                            handleMsgSent(e);
                            setRows(1);
                        }
                        e.preventDefault(); // Prevent default to avoid creating a new line when sending
                    }
                }}
                placeholder="Type your message"
                multiline
                rows={rows}
                maxRows={maxRows}
                variant="outlined"
                style={{
                    marginRight: "10px",
                    marginTop: "5px",
                    lineHeight: "0.5",
                    width: "100%",
                    overflowY: 'auto',
                    overflowX: 'hidden', // Prevent horizontal overflow
                    whiteSpace: 'pre-wrap', // Wrap text to new line
                }}
            />


            <Button
              variant="contained"
              style={{
                width: "40px",
                minWidth: "20px",
                height: "40px",
                backgroundColor: "#016D77",
                // marginTop: "-50px",
              }}
              onClick={handleMsgSent}
              disabled={loadingStore.fileUploading || isBotThinking}
            >
              <SendIcon style={{ fontSize: "20px" }} />
            </Button>
          </div>
        </div>
      </Card>
    );
  }
);
