import { axiosStore } from '../store';

class AuthService {
    // async fetch_token(username){
    //     const data = {
    //         user_id: username,
    //         ad_access_token: 'null'
    //     };
    //     const response = await axiosStore.authAxios.post('/auth/token', data);
    //     return response.data.access_token;
    // }
}

export default AuthService