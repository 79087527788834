import MaterialTable from '@material-table/core';
import { GroupUserManageTableAction } from '../../enums/table';
import { useEffect, useRef, useState } from 'react';
import GroupSelect from '../groupSelect';
import { notiStore } from '../../store';
import { groupUserService } from '../../service';
import { observer } from 'mobx-react-lite';
import { CircularProgress, IconButton } from '@mui/material';
import { CloudDownload, CloudUpload } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ConfirmDialog from '../confirmDialog';

const UploadIconButton = observer(({ oc, group_id, disabled = false, fileId='fileinput-groupuser' }) => {
  const [loading, setLoading] = useState(false);
  const handleFileChange = async (event) => {
    setLoading(true)
    // oc()
    const file = event.target.files[0];
    if(file) {
      await oc(file, group_id)
    }
    setTimeout(() => {
      setLoading(false)
      document.getElementById(fileId).value = null;
    })
  }

  const handleOnClicked = () => {
    document.getElementById(fileId).click();
  }

  return <>
          <IconButton
              sx={{
                  marginRight: '3px'
              }}
              disabled={disabled}
              onClick={handleOnClicked}
          >
              {loading ? (
                  <div>
                      <CircularProgress size={15} style={{ color: 'grey'}} />
                  </div>
              ) : <CloudUpload />}
              <input
                  type="file"
                  id={fileId}
                  style={{ display: "none" }}
                  accept=".xlsx, .csv"
                  onChange={handleFileChange}
              />
          </IconButton>
      </>
})

const GroupUserManagementTable = ({ group_list }) => {
  const [currentGroup, setGroup] = useState(group_list[0])
  const [loading, load] = useState(false)
  const [page, setPage] = useState(0)
  const tableRef = useRef(MaterialTable);
  const [queryFunc, setQueryFunc] = useState(null)

  const CustomTitle = () => (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '20px' }}>
          <div style={{ margin: 0 }}>Group {currentGroup.name}</div>
          <GroupSelect
              selectGroup={setGroup}
              groupList={group_list}
              defaultGroup={group_list[0]}
          />
      </div>
  );

  const tableColumns = [
    // {
    //     'title': 'User',
    //     'field': 'user',
    // },
    {
        title: 'Email',
        field: 'email',
        // editable: 'onAdd'
        
    },
    // {
    //   title: 'Created At',
    //   field: 'created_at',
    //   width: '15px',
    //   editable: false
    // },
    {
      title: 'Updated',
      field: 'updated_at',
      width: '250px',
      editable: false
    },
    {
        title: 'InSystem',
        field: 'inSystem',
        editable: false,
        width: '50px',
        render: rowData => (
          <div style={{
              color: rowData.inSystem ? '#008916' : '#FF1C1C', // Text color
              // backgroundColor: rowData.inSystem ? '#E8FFEB' : '#FFE1E1',
              borderRadius: '20px', // Rounded corners for pill shape
              padding: '0px 10px', // Vertical and horizontal padding
              textAlign: 'center',
              display: 'inline-block',
              minWidth: '60px' // Minimum width to keep the pill shape consistent
          }}>
              {rowData.inSystem ? <CheckCircleOutlineIcon /> : <CancelOutlinedIcon/>}
          </div>
      ),
    },
  ];

  const onStart = async (group_id) => {
      // Use current group if no group_id is provided
      if(!group_id){
        group_id = currentGroup.id
      }
      // tableRef.onPageChange(0)
      tableRef.current.onQueryChange()
      setTimeout(() => load(false));
    }

  const uploadFile = async (file, group_id) => {
      try {
          await groupUserService.upload_file(file, group_id);
          notiStore.open({ message: "File upload successfully" });
          await onStart();
      } catch (error) {
          notiStore.error(error, "Error uploading file");
          console.error("Error uploading file:", error);
      }
  };

  const fetchData = async (query) => {
    const Q = {
      page: query.page+1,
      pageSize: query.pageSize ?? 10,
      globalSearchValue: query.search ?? '',
      sortField: query.orderBy && query.orderBy != undefined ? query.orderBy.field : '',
      sortFieldOrder: (query.orderDirection == 'asc' ? 1: (query.orderDirection == '' ? 0 : -1)) ?? 0
    }
    const { users, total } = await groupUserService.fetchAllUser(currentGroup.id, Q)
    return {
      data: users,
      page: query.page,
      totalCount: total
    }
  }
  
  const [userData, setUserData] = useState(null);
  const [userAction, setUserAction] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleConfirmDialog = async () => {
    setIsDialogOpen(false);

    try{
      switch (userAction) {
        case GroupUserManageTableAction.Add:
            // group_id should be based from current group
            await groupUserService.create(currentGroup.id, userData.email) 
            break;
        case GroupUserManageTableAction.Edit:
            await groupUserService.edit(currentGroup.id, userData.id, userData.email)
            break;
        case GroupUserManageTableAction.Remove:
            await groupUserService.remove(currentGroup.id, userData.id)
            break;
      }
    } catch (error) {
      notiStore.error(error, 'Handle action on group user failed')
    }
    

    setTimeout(() => load(false), 500);
    onStart(currentGroup.id)
  };

  const handleCloseDialog = () => {
      setIsDialogOpen(false);
      load(false);
  };

  const handleAction = async (action, user) => {
    load(true)

    console.log(`Action: ${action}`);
    console.log(user)
    setUserAction(action);
    setUserData(user);
    setIsDialogOpen(true);
  };

  useEffect(() => {
    if(currentGroup){
      onStart(currentGroup.id)
    }
  }, [currentGroup])

  return <div><MaterialTable
    title={<CustomTitle />}
    columns={tableColumns}
    data={fetchData}
    isLoading={loading}
    tableRef={tableRef}
    options={{
      paging: true,
      addRowPosition: 'first',
      filtering: false,
      exportButton: true,
      minBodyHeight: '70vh',
      maxBodyHeight: '70vh',
      padding: 'dense',
      pageSize: 10,
      pageSizeOptions: [10],
      debounceInterval: 600,
      actionsColumnIndex: -1,
      headerStyle: {
        backgroundColor: '#e0e0e0',
        color: '#000',
        fontWeight: 'bold',
        fontSize: '16px',
        border: 'none',
      },
      actionsCellStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '6px 16px'
      }
    }}
    // totalCount={totalCount}
    page={page}
    actions={[
        // {
        //     icon: () => GroupSelect({
        //       // defaultGroup: group_list[0],
        //       selectGroup: setGroup,
        //       groupList: group_list
        //     }),
        //     isFreeAction: true,
        //   tooltip: 'Group select'
        // },
        {
          // icon: () => <DriveFolderUploadIcon />,
          icon: () => <UploadIconButton oc={uploadFile} group_id={currentGroup.id} />,
          isFreeAction: true,
          tooltip: 'Bulk user upload'
        },
        {
          icon: () => <div style={{ marginLeft: '1px', marginTop: '7px', marginRight: '6px', cursor: 'pointer' }}> <CloudDownload /> </div>,
          isFreeAction: true,
          tooltip: 'Download template',
          onClick: () => window.open('https://scgo365.sharepoint.com/:f:/s/Corpservices/ElWw8pUOHrtJs4njIZIUhsUBZM8-6aw2MBLlFoXMsrx7Zw?e=GOpYJo', '_blank')
        },
        
    ]}
    editable={{
      onRowUpdate: (rowData) => handleAction(GroupUserManageTableAction.Edit, rowData),
      onRowAdd: (rowData) => handleAction(GroupUserManageTableAction.Add, rowData),
      onRowDelete: (rowData) => handleAction(GroupUserManageTableAction.Remove, rowData)
    }}
  /><ConfirmDialog 
  open={isDialogOpen}
  onClose={handleCloseDialog}
  onConfirm={handleConfirmDialog}
  message={'Are you sure to change/delete this user?'}
/>
</div>
}
export default GroupUserManagementTable;