import { adminStore, authStore, axiosStore } from "../store";

class GroupService {

    async fetchAllGroup() {
        // Fetch all group that user can access
        const response = await axiosStore.baseAxios.get('/user/group')
        authStore.access_group_list = response.data
        return response.data
    }

    async create(group) {
        await axiosStore.baseAxios.post('/group', {
            name: group.name,
            admin_list: group.admin_list,
            group_type: group.group_type
        })   
    }

    async edit(group_id, group) {
        await axiosStore.baseAxios.put(`/group/${group_id}`, {
            name: group.name,
            admin_list: group.admin_list,
            group_type: group.group_type
        })   
    }

    async remove(group_id) {
        await axiosStore.baseAxios.delete(`/group/${group_id}`)
    }

}

export default GroupService