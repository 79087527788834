import MaterialTable from '@material-table/core';
import { UserRole } from '../../enums/user';
import { MembershipTableAction } from '../../enums/table';
import { useEffect, useRef, useState } from 'react';
import { userService } from '../../service';
import ConfirmDialog from '../confirmDialog'; 

function getRoleColor(role) {
  switch (role) {
    case UserRole.Member:
      return '#DBF1EF'; // green
    case UserRole.Owner:
      return '#DBF1EF'; // blue
    case UserRole.Admin:
      return '#DBF1EF'; // red
    default:
      return '#DBF1EF'; // default grey
  }
}

const UserMangementTable = () => {
    const tableRef = useRef();
    const tableColumns = [
        { 
          title: 'User',
          field: 'preferred_username',
          editable: 'never',  // This column is not editable
          filtering: false,
          headerStyle: {
            border: 'none',
          },
          rowStyle: {
            fontSize: '11px',  // Setting font size to 14 pixels for each row
            lineHeight: '0.8',
          }
        },
        { 
          title: 'Role',
          field: 'role',
          lookup: {
                [UserRole.Member]: UserRole.Member,
                [UserRole.Owner]: UserRole.Owner,
                [UserRole.Admin]: UserRole.Admin
          },
          render: rowData => (
            <div style={{
              color: 'black', // text color
              backgroundColor: getRoleColor(rowData.role), // dynamic background based on role
              borderRadius: '20px', // rounded corners for pill shape
              padding: '5px 10px', // vertical and horizontal padding
              textAlign: 'center',
              display: 'inline-block',
              fontSize: '11px',
              lineHeight: '0.8',
              minWidth: '60px' // minimum width
            }}>
              {rowData.role.charAt(0).toUpperCase() + rowData.role.slice(1)}
            </div>
          ),
          filtering: false,
          headerStyle: {
            border: 'none',
          },
          editCellStyle: {
            border: 'none',  // Remove borders in edit mode for this column
          },
          rowStyle: {
            fontSize: '11px',  // Setting font size to 14 pixels for each row
            lineHeight: '0.8',
          }
        },
        {
          title: 'Join At',
          field: 'created_at',
          editable: 'never',  // This column is not editable
          filtering: false,
          headerStyle: {
            border: 'none',
          },
          rowStyle: {
            fontSize: '11px',  // Setting font size to 14 pixels for each row
            lineHeight: '0.8',
          },
          width: '250px',
        },
        {
          title: 'Last Login',
          field: 'last_accessed_at',
          editable: 'never',  // This column is not editable
          filtering: false,
          headerStyle: {
            border: 'none',
          },
          width: '250px',
          rowStyle: {
            fontSize: '11px',  // Setting font size to 14 pixels for each row
            lineHeight: '0.8',
          }
        },
    ];

    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleConfirmDialog = async () => {
      setLoading(true)
      setIsDialogOpen(false);
      await userService.changeRole(userData['oid'], userData['role']);
      if(tableRef){
        tableRef.current.onQueryChange()
      }
      setTimeout(() => setLoading(false), 500);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setLoading(false);
    };

    const fetchUsers = async (query) => {
      const response = await userService.fetchAllUsers(query);
      return { data: response.data, totalCount: response.totalCount }
    };

    const onFetcUsers = async (query)=> {
      // if(query && qyery.)

      // debugger
      // let construct_query = {}
      // if(query && query != undefined){
      //   // console.log(query.orderBy.field)
      //   // console.log(query.orderDirection)
      //   // console.log(query.search)
      //   console.log(query)
      //   construct_query = {
      //     page: query.page+1,
      //     pageSize: query.pageSize ?? 10,
      //     globalSearch: query.search ?? '',
      //     sortField: query.orderBy && query.orderBy != undefined ? query.orderBy.field : '',
      //     sortFieldOrder: (query.orderDirection == 'asc' ? 1: (query.orderDirection == '' ? 0 : -1)) ?? 0
      //   }
      // } else {
      //   construct_query = {
      //     page: 1,
      //     pageSize: 10
      //   }
      // }
      const Q = {
        page: query.page+1,
        pageSize: query.pageSize ?? 10,
        globalSearchValue: query.search ?? '',
        sortField: query.orderBy && query.orderBy != undefined ? query.orderBy.field : '',
        sortFieldOrder: (query.orderDirection == 'asc' ? 1: (query.orderDirection == '' ? 0 : -1)) ?? 0
      }
      console.log(Q)
      const {data, totalCount} = await fetchUsers(Q)
      return {
          data: data ?? [],
          page: query.page,
          totalCount: totalCount ?? 0,
      };
    };



    const onStart = async () => {
      setLoading(true);
      await fetchUsers();
      setTimeout(() => setLoading(false), 500);
    };

    const handleAction = async (action, user) => {
      // setLoading(true);
      switch (action) {
          case MembershipTableAction.Edit:
              setUserData(user);
              setIsDialogOpen(true);
      }
      
    };

    useEffect(() => {
      onStart();
    }, []);


    return (
        <div>
        <MaterialTable
            title=""
            columns={tableColumns}
            tableRef={tableRef}
            data={onFetcUsers}
            pageSize={10}
            isLoading={loading}
            options={{
                search: true,
                paging: true,
                filtering: false,
                exportButton: true,
                minBodyHeight: '320px',
                maxBodyHeight: '70vh',
                padding: 'dense',
                actionsColumnIndex: -1,
                pageSize: 10,
                pageSizeOptions: [10, 20],
                fontSize: '11px',
                debounceInterval: 500,
                headerStyle: {
                    backgroundColor: '#e0e0e0',
                    color: '#000',
                    fontWeight: 'bold',
                    fontSize: '11px',
                    border: 'none',
                },
                rowStyle: {
                  fontSize: '11px',  // Setting font size to 14 pixels for each row
                  lineHeight: '1',
                },
                searchFieldStyle: {
                  fontSize: '11px',
                  minHeight: '24px'
                },
                actionsCellStyle: {
                  width: '100px',
                  paddingLeft: '30px'
                }
                
            }}
            editable={{
                onRowUpdate: (newData) => handleAction(MembershipTableAction.Edit, newData),
                editCellStyle: {
                    border: 'none',  // Apply no border style globally to editable cells
                },
            }}
        />
        <ConfirmDialog 
                open={isDialogOpen}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmDialog}
                message={'Are you sure to change this user role?'}
            />
           </div> 
    );
}

export default UserMangementTable;
