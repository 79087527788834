import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { configure } from 'mobx';
import Button from '@mui/material/Button';
import ChatIcon from '@mui/icons-material/Chat';
import DeleteIcon from '@mui/icons-material/Delete';
import { authStore, sessionStore } from '../store';
import { sessionService } from '../service';
import ConfirmDialog from './confirmDialog';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import { isMobile } from 'react-device-detect';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

configure({
    enforceActions: 'never',
});

const SideBar = observer(({ sessionStartRef,sessionHistory, show, fetchAsset, setCurrentSession, handleNewChat, currentSession, setIsImageModalOpen, setShowSidebar, handleAdminModal }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [sessionToDelete, setSessionToDelete] = useState(null);

    const onDeleteSession = (session_id) => {
        setSessionToDelete(session_id);
        setIsDialogOpen(true);
    };

    const handleConfirmDelete = async () => {
        setIsDialogOpen(false);
        if (sessionToDelete) {
            sessionService.delete_session_by_id(sessionToDelete);
            await fetchAsset();
        }
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleSidebar = () => {
        setShowSidebar(!show);
    };

    const handleSessionClicked = (session) => {
        setCurrentSession(session)
        // Setup file use memory
        sessionStore.setFileFromMemory(session)
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: show ? 0 : '-250px',
                height: '100vh',
                width: '250px',
                background: '#eee',
                transition: 'left 0.3s',
                overflow: 'hidden',
                padding: '10px'
            }}
        >
            {isMobile && <div style={{ paddingLeft: '200px', paddingBottom: '3px' }}>
                <Button
                    style={{
                        minWidth: '30px',
                        height: '30px',
                        width: '30px',
                        padding: 0,
                        borderRadius: '15px',
                        color: '#016D77',
                        fontSize: '16px',
                    }}
                    onClick={handleSidebar}
                >
                    X
                </Button>
            </div>}
            <div style={{ background: '#f2f2f2', marginBottom: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button
                    style={{ 
                        height: '50px', 
                        width: authStore.settingPaneAccessible ? '100%' : '100%', 
                        backgroundColor: '#016D77',
                        color: 'white'
                    }}
                    variant="contained"
                    startIcon={<ChatIcon />}
                    onClick={handleNewChat}
                >
                    NewChat
                </Button>
                {/* {authStore.settingPaneAccessible && (
                    <Button
                        style={{ 
                            height: '50px', 
                            width: '45%', 
                            backgroundColor: '#016D77',
                            color: 'white'
                        }}
                        variant="contained"
                        startIcon={<AdminPanelSettingsIcon />}
                        onClick={handleAdminModal}
                    >
                        Admin
                    </Button>
                )} */}
            </div>

            <div style={{ height: isMobile ? 'calc(100vh - 180px)' : 'calc(100vh - 150px)', overflowY: 'auto', marginTop: '10px' }}>
                {sessionHistory.map((session, index) => (
                    <Button
                        key={index}
                        ref={index === 0 ? sessionStartRef : null}
                        style={{
                            background: session === currentSession ? 'rgb(255 255 255)' : 'transparent',
                            color: session === currentSession ? '#016D77' : '#016D77',
                            marginTop: '10px',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            fontSize: '10px',
                            padding: '10px',
                            borderBottom: '1px solid #ccc',
                        }}
                        color="primary"
                        onClick={() => {
                            handleSessionClicked(session)
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ChatIcon />
                            <div style={{ marginLeft: '10px', color: 'black', fontSize: '12px' }}>
                                <div>
                                    <span> {session.topic.length > 14 ? session.topic.substring(0, 14) + "..." : session.topic + "  "} </span>
                                </div>
                            </div>
                        </div>
                        
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <DeleteIcon style={{ paddingBottom: '3px' }} onClick={(e) => {
                                e.stopPropagation();
                                onDeleteSession(session.session_id);
                            }} />
                            <div style={{ fontSize: '8px', color: 'gray' }}>
                                {new Date(session.updated_at).toLocaleString("en-US", {
                                    day: "numeric",
                                    month: "short",
                                })}
                            </div>
                        </div>
                    </Button>
                ))}
            </div>
            <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button
                    style={{ 
                        height: '40px', 
                        width: '50%', 
                        backgroundColor: '#FFF',
                        border: '2px solid #F8A59C',
                        color: '#F8A59C',
                        lineHeight: 1
                    }}
                    variant="contained"
                    // startIcon={<ErrorOutline />}
                    onClick={() => {
                        const link = document.createElement('a');
                        link.href = 'https://forms.office.com/r/3umH4b7yew';
                        link.target = '_blank';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }}
                >
                    <ErrorOutline style={{fontSize: '28px', color: '#F8A59C'}}/>
                    Report Issue
                </Button>
                <Button
                    style={{ 
                        height: '40px', 
                        width: '50%', 
                        backgroundColor: '#fff',
                        color: '#77BFB8',
                        border: '2px solid #77BFB8' 
                    }}
                    variant="contained"
                    // startIcon={<LocalLibraryOutlinedIcon />}
                    onClick={() => {
                        // setIsImageModalOpen(true)
                        const link = document.createElement('a');
                        link.href = 'https://scgo365.sharepoint.com/sites/Corpservices/SitePages/GINII-(-SCG-Private-Chat-GPT-).aspx';
                        link.target = '_blank';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }}
                >
                    <LocalLibraryOutlinedIcon style={{fontSize: '28px', color: '#77BFB8'}}/>
                    Manual
                </Button>
            </div>
            <ConfirmDialog 
                open={isDialogOpen}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmDelete}
                message={'Are you sure to delete this session?'}
            />
        </div>
    );
});

export default SideBar;
