import { GroupType } from "./group"
import { UserRole } from "./user"
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import GroupWorkRoundedIcon from '@mui/icons-material/GroupWorkRounded';
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import SnippetFolderRoundedIcon from '@mui/icons-material/SnippetFolderRounded';

import DisplaySettingsRoundedIcon from '@mui/icons-material/DisplaySettingsRounded';
export const AdminManageTabIcon = {
    ManageUser: ManageAccountsRoundedIcon,
    ManageGroup: GroupWorkRoundedIcon,
    ManageGroupUser: GroupAddRoundedIcon,
    ManageFolder: SnippetFolderRoundedIcon,
    ManagePrompt: DisplaySettingsRoundedIcon
};

export const AdminManageTab = {
    ManageUser: 'Manage User',
    ManageGroup: 'Manage Group',
    ManagePrivateGroupUser: 'Manage Group User',
    ManageFolder: 'Manage Asset',
    ManagePrompt: 'Prompt Setting'
    // ManageFolderV2: 'Manage folder/file V2',
    // ManagePrompt: 'Prompt Management',
    // ManageQuota: 'Quota Management',
}


export const getManageTabList = (user, maintain_groups, maintain_private_group_list) =>  {
    return [
        {
            name: AdminManageTab.ManageUser,
            permitted: user.role == UserRole.Admin
        },
        {
            name: AdminManageTab.ManageGroup,
            permitted: user.role == UserRole.Admin
        },
        {
            name: AdminManageTab.ManagePrivateGroupUser,
            permitted: (user.role == UserRole.Admin || user.role == UserRole.Owner) && maintain_private_group_list.length > 0
        },
        {
            name: AdminManageTab.ManageFolder,
            permitted:  (user.role == UserRole.Admin || user.role == UserRole.Owner) && maintain_groups.length > 0
        },
        {
            name: AdminManageTab.ManagePrompt,
            permitted: true
        }
    ].filter(tab => tab.permitted).map(tab => tab.name)
}

// Get all tabs array
export const AdminManageTabArray = Object.values(AdminManageTab)
export const AdminManageTabArrayIcon = Object.values(AdminManageTabIcon)
