import { makeAutoObservable  } from 'mobx';

class FilePreviewModalStore {
    default_file = {
        "title": "Default title",
        "summarize_content": "Default summarize content"
    }
    file = null
    isOpen = false

    
    constructor() {
        makeAutoObservable(this);
    }

    open() {
        this.isOpen = true
    }

    close(){
        this.isOpen = false
        this.file = {...this.default_file}
    }

    setFile(file){
        this.file = file
    }

    get file_wclean_summary(){
        return this.file && this.file.summary ? { ...this.file, ...{ summary: this.file.summary.replace('```markdown', '').replace('```', '')}} : this.file
    }

}

export default FilePreviewModalStore;