import MaterialTable from "@material-table/core";
import FormControl from "@mui/material/FormControl";
import { UserRole } from "../../enums/user";
import { AssetType } from "../../enums/manage";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import { AssetManageTableAction } from "../../enums/table";
import { forwardRef, useState, useEffect } from "react";
import { GroupSelect } from "../groupSelect";
import { assetService, fileGroupService } from "../../service";
import FolderIcon from "@mui/icons-material/Folder";
import DescriptionIcon from "@mui/icons-material/Description";
import ConfirmDialog from '../confirmDialog'; 
import {
  Autocomplete,
  CircularProgress,
  FormHelperText,
  IconButton,
  Input,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { FolderSelector } from "./folder_selector";
import { notiStore } from "../../store";
import { FileStatus } from "../../enums/file";
import { CloudUploadSharp, Delete } from "@mui/icons-material";
import { Severity } from "../../enums/severity";
const RenderNameComponent = (rowData) => {
  const hasParentId = rowData.parent_id != undefined;
  const preIcon =
    rowData.asset_type == AssetType.Folder ? (
      <>
        <FolderOpenOutlinedIcon />{" "}
      </>
    ) : rowData.asset_type == AssetType.SubFolder ? (
      <>
        <FolderIcon />
      </>
    ) : (
      <>
        <DescriptionIcon />
      </>
    );
  const shiftLength = rowData.level * 20;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginLeft: hasParentId ? `${shiftLength}px` : "0",
      }}
    >
      {preIcon}
      <span style={{ marginLeft: "5px" }}>{rowData.name}</span>
      {/*Show status only for file */}
      {rowData.asset_type == AssetType.File && rowData.status != 'done' && rowData.status != 'Done' && (
        <span style={{ marginLeft: "5px" }}>( {rowData.status} )</span>
      )}
    </div>
  );
};

const FileUploadButton = ({ handleAction, rowData }) => {
  const fileId = `file-${rowData.id}`;
  const [loading, setLoading] = useState(false)
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setLoading(true)
    if (file) {
      await handleAction(AssetManageTableAction.Upload, {...rowData, file_content: file});
    }
    setTimeout(()=> {
      setLoading(false)
      document.getElementById(fileId).value = null;
    }, 500)
  };

  const handleOnClicked = () => {
    document.getElementById(fileId).click();
  }


  return (
    <IconButton
      sx={{ marginRight: '3px', background: 'transparent', padding: '0px' }}
      onClick={handleOnClicked}
    >
      {loading ? (
        <div>
          <CircularProgress size={15} style={{ color: 'grey'}} />
        </div>
      ) : <CloudUploadSharp />}
      <input
        type="file"
        id={fileId}
        style={{ display: "none" }}
        accept=".pdf, .docx, .txt, .pptx, .xlsx, .csv"
        onChange={handleFileChange}
      />
    </IconButton>
  );
};


const EditNameComponent = ({ folderList, ...props }) => {
  const isCreateMode = Object.keys(props.rowData).length === 0 || props.rowData.id === undefined
  const isSubFolderCreate = props.rowData.asset_type && props.rowData.asset_type == AssetType.SubFolder
  // console.log(props.rowData)
  // console.log(isCreateMode)
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {/* Not edit mode */}
      {true && (
        <FormControl style={{ width: '150px' }}>
          <Select
            size='small'
            value={props.rowData.asset_type}
            onChange={(e) => {
                props.onRowDataChange({...props.rowData, 'asset_type': e.target.value, 'parent_id': folderList[0].id});
            }}
            disabled={!isCreateMode}
            defaultValue={AssetType.Folder}
            renderValue={(value) => {
                const renderIcon = value === AssetType.Folder ? <><FolderOpenOutlinedIcon /></> : <><FolderIcon /></>;
                // return <div style={{ display: 'flex', alignItems: 'center'}}>
                //     {'Ass'}
                // </div>
                return <Stack direction="row">
                  {renderIcon}
                  <div style={{ marginLeft: '5px'}}>
                    {value === AssetType.Folder ? 'Folder' : 'Sub Folder'}
                  </div>
                </Stack>
              }}
            sx={{ 
              // border: 'none',
              marginTop: '4px',
              padding: 0,
              // boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }
            }} // Remove border and set padding to 0
        >
            <MenuItem value={AssetType.Folder}><FolderOpenOutlinedIcon sx={{ marginRight: '5px' }} />Folder</MenuItem>
            <MenuItem value={AssetType.SubFolder}><FolderIcon sx={{ marginRight: '5px' }} />Sub Folder</MenuItem>
        </Select>
        </FormControl>
      )}
      {isCreateMode && isSubFolderCreate && (
        <>
          <Autocomplete
              size="small"
              sx={{
                marginTop: '4px',
                width: '150px',
                marginLeft: '10px'
              }}
              disableClearable
              options={folderList}
              getOptionLabel={(option) => option.name}
              defaultValue={folderList[0]}
              onChange={(e, v) => {
                  // if(v.id){
                  console.log(`on change ${v.id}`)
                  props.onRowDataChange({...props.rowData, 'parent_id': v.id});
                  // }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="เลือก folder"
                />
              )}
          />
        </>
      )}
      <TextField
        size='small'
        value={props.value}
        onChange={(e) => {
          // if(e.target.value){
            // props.onChange(e.target.value)
          // }
          props.onRowDataChange({...props.rowData, 'name': e.target.value});
        }}
        label="Asset Name"
        sx={{
          marginTop: '4px',
          fontSize: "0.875rem",
          padding: "0px",
          marginLeft: "10px",
        }}
      />
    </div>
  );
};

const AssetManagementTable =  ({ group_list }) => {

  const [currentGroup, setGroup] = useState(group_list[0])
  const [tableData, setTableData] = useState([])
  const folderList = tableData.filter(item => item.asset_type === AssetType.Folder)
  // .map(item => item.name);
  const [loading, load] = useState(false);
  const [confirmActionMessage, setConfirmActionMessage] = useState(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [handleConfirmDialog, setConfirmDialog] = useState(null)
  const tableColumns = [
    // {
    // title: 'Type', field: 'asset_type', editable: false,
    // lookup: {
    //   [AssetType.Folder]: AssetType.Folder,
    //   [AssetType.SubFolder]: AssetType.SubFolder,
    //   [AssetType.File]: AssetType.File
    // },
    // render: rowData => rowData.asset_type == AssetType.Folder ? <><FolderOpenOutlinedIcon /> </> : (rowData.asset_type == AssetType.SubFolder ? <><FolderIcon /></>: <></>),
    // filtering: false,
    // editable: 'onAdd',
    // editComponent: (props) => <>Folder/File</>,
    // width: '10px'
    // },
    {
      title: "Name",
      field: "name",
      width: "300px !important",
      // MIgrate render from asset_type, merge asset type and name to here
      render: RenderNameComponent,
      editComponent: (props) => <EditNameComponent {...props} folderList={folderList} />,
    },
    {
      title: "Updated",
      field: "updated_at",
      editable: false,
      width: "100px !important",
    },
  ];

  const fetchAsset = async (group_id) => {
    let response = await assetService.get_asset_list(group_id)
    // response = response.map(item => {
    //   const level =  generateLevel(item.parent_id, response);
    //   console.log(level)
    //   return { ...item, level }
    // })
    console.log(`fetch asset ${response}`)
    response.forEach(item => {
      console.log(item);
    });
    // tableData.forEach((item) => {
    //   item.level = generateLevel(item.parent_id);
    // });

    setTableData(response)
  }

  const CustomTitle = () => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '20px' }}>
        <div style={{ margin: 0 }}>Group {currentGroup.name}</div>
        <GroupSelect
            selectGroup={setGroup}
            groupList={group_list}
            defaultGroup={group_list[0]}
        />
    </div>
  );

  const onStart = async (group_id) => {
    // Use current group if no group_id is provided
    if(!group_id){
      group_id = currentGroup.id
    }
    load(true);
    // setTableData([]);

    await fetchAsset(group_id)
    setTimeout(() => load(false), 1000);
  }

  const handleCloseDialog = () => {
      setIsDialogOpen(false);
      load(false);
  };

  const handleAction = async (action, asset) => {
    // if (!window.confirm(`Confirm ${action}`)) {
    //   return;
    // }
    // console.log(asset)
    if (action != AssetManageTableAction.Upload){
      load(true);
    }

    console.log(`Action: ${action}`);
    // setRowAction(action)
    // setRowData(user)
    const onConfirmDialog = () => (async () => {
      setIsDialogOpen(false)
      try {
        switch (action) {
          case AssetManageTableAction.Add:
            // group_id should be based from current group
            await assetService.create(
              currentGroup.id, 
              asset.name,
              asset.asset_type,
              asset.parent_id
            );
            notiStore.open({ message: 'Asset created successfully', severity: Severity.Success });
            break;
          case AssetManageTableAction.Edit:
            await assetService.update(asset.id, asset.name);
            notiStore.open({ message: 'Asset updated successfully', severity: Severity.Success });
            break;
          case AssetManageTableAction.Remove:
            if(asset.asset_type == AssetType.File) {
              await fileGroupService.delete_file(asset.id)
            } else {
              await assetService.delete(asset.id);
            }
            notiStore.open({ message: 'Asset/File deleted successfully', severity: Severity.Success });
            break;
          case AssetManageTableAction.Upload:
            // if(asset.file_content){
            // console.log("Input parameters: ", asset.file_content, asset.group_id, asset.id);
            await fileGroupService.upload_file(asset.file_content, asset.group_id, asset.id)
            // }
            // console.log(asset.file_content)
            notiStore.open({ message: 'Asset/File uploaded successfully', severity: Severity.Success });
            break
        }
      } catch (error) {
        console.log(error)
        console.error(error)
        notiStore.error(error, 'Something went wrong.');
      }
  
      onStart()
    })
    setConfirmDialog(onConfirmDialog)
    setConfirmActionMessage(action)
    setIsDialogOpen(true)
  };

  // useEffect(()=> {
  //   // tableRef.current.on
  // }, [tableRef])


  useEffect(() => {
    if(currentGroup){
      onStart(currentGroup.id)
    }
  }, [currentGroup])

  return <>
  <MaterialTable
    title={<CustomTitle />}
    columns={tableColumns}
    data={tableData}
    isLoading={loading}
    options={{
      draggable: false,
      // search: true,
      sorting: false,
      paging: false,
      defaultExpanded: true,
      addRowPosition: 'first',
      filtering: true,
      exportButton: true,
      actionsCellStyle: { direction: 'revert-layer', },
      actionsColumnIndex: -1,
      overflowY: 'scroll',
      minBodyHeight: '75vh',
      maxBodyHeight: '75vh',
      filtering: false,
      // selection: true,
      // detailPanelType: "single",
      padding: 'dense',
      showDetailPanelIcon: false,
      headerStyle: {
        position: 'sticky',
        top: 0,
        fontWeight: 'bold',
        fontSize: '16px',
        background: '#e0e0e0',
        "z-index": '100'
      },
      actionsCellStyle: {
        display:"flex",
        justifyContent: "flex-end",
        width: '100%',
        marginTop: '5px',
        paddingRight: '25%'
        // paddingLeft: '30px'
      }
    }}
    // icons={{
    //   DetailPanel: ({level}) => {
    //     if (level === 0) {
    //       return level;
    //     }
    //     return <ChevronRight data-testid="chevron_right" sx={{ paddingLeft: '3px'}} />
    //   },
    //   Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    //   Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    //   Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
    // }}
    // icons={{
    //   // Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />)
    // }}
    actions={[
      (rowData) => ({
        icon: () => {
          // if (rowData.asset_type === AssetType.File) {
          //   return <div style={{ marginLeft: '60px', pointerEvents: 'none', opacity: '0', outlineStyle: 'none' }}></div>
          // }
          return (
            <Tooltip title='Upload File'>
              <FileUploadButton handleAction={handleAction} rowData={rowData} />
            </Tooltip>
          )
        },
          // tooltip: 'Upload File',
        isFreeAction: false,
        hidden: rowData.asset_type == AssetType.File
          // onClick: null,
          // disabled: rowData.asset_type == AssetType.File || true,
        // actionsCellStyle: {
        //   direction: 'rtl'
        // }
      }),
      // icons={{
      //   DetailPanel: ({level}) => {
      //     if (level === 0) {
      //       return level;
      //     }
      //     return <ChevronRight data-testid="chevron_right" sx={{ paddingLeft: '3px'}} />
      //   },
      //   Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      //   Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      //   Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
      // }}
      // {
      //   icon: () =>
      //     GroupSelect({
      //       selectGroup: setGroup,
      //       // groupList: ['SCG', 'A', 'B']
      //       groupList: group_list,
      //     }),
      //   isFreeAction: true,
      //   // onClick: () => handleAction(AssetManageTableAction.ChangeGroup),
      //   // hidden: rowData.asset_type != AssetType.Folder,
      //   // disabled: rowData.role == UserRole.None,
      // },
      ]}
      editable={{
        onRowAdd: (rowData) =>
          handleAction(AssetManageTableAction.Add, rowData),
        onRowUpdate: (rowData) =>
          handleAction(AssetManageTableAction.Edit, rowData),
        onRowDelete: (rowData) =>
          handleAction(AssetManageTableAction.Remove, rowData),
        isEditHidden: (rowData) => rowData.asset_type == AssetType.File,
      }}
      // sx={{
      //   height: '100%',
      //   overflowY: 'auto'
      // }}
      parentChildData={(row, rows) => rows.find((a) => a.id === row.parent_id)}
    />
  <ConfirmDialog 
    open={isDialogOpen}
    onClose={handleCloseDialog}
    onConfirm={handleConfirmDialog}
    message={`Are you sure to ${confirmActionMessage} this asset?`}
  />
  </>
};
export default AssetManagementTable;
