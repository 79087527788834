
export const MembershipTableAction = {
    Approve: 'approve',
    Add: 'add',
    Remove: 'remove',
    Edit: 'edit',
}

export const GroupManageTableAction = {
    Add: 'add',
    Remove: 'remove',
    Edit: 'edit'
}

export const GroupUserManageTableAction = {
    Add: 'add',
    Remove: 'remove',
    Edit: 'edit',
    BulkUpload: 'bulkUpload'
}


export const AssetManageTableAction = {
    Add: 'add',
    Remove: 'remove',
    Edit: 'edit',
    Upload: 'upload',
    // ChangeGroup: 'changeGroup'
}