import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import GroupsIcon from '@mui/icons-material/Groups';
import FolderIcon from '@mui/icons-material/Folder';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import { withStyles } from '@mui/styles';

import { Button, Drawer, List, ListItem, ListItemText } from '@mui/material';

import { fileModalStore, notiStore, sessionStore } from '../store';
import { useState } from 'react';
import { FileStatus, FileMode } from '../enums/file';
// import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { observer } from 'mobx-react-lite';
import { ResponseMode } from '../enums/qa';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import { fileService } from '../service';
import RightModal from '../components/rightModal'; // Import the RightModal component
import { isMobile } from 'react-device-detect';
import { authStore } from '../store';
import SettingsIcon from '@mui/icons-material/Settings';
import { ModelDisplay, ModelOption } from '../enums/prompt';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

export const FilterComponent = observer(({fetchNewFile, toggleSidebar, handleAdminModal}) => {

    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
    <>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center', margin: '5px', marginTop: '15px' }}>
                <MenuIcon style={{ marginRight: '10px', marginLeft: isMobile ? '30px' : '50px', fontSize: '30px', color: '#016D77' }} onClick={toggleSidebar} />
                {/* <FormControl style={{ margin: '5px', minWidth: 50, width: '160px' }}>
                    { <Select
                        labelId="gpt-selector-label"
                        id="gpt-selector"
                        value={sessionStore.model_type}
                        defaultValue={ModelOption.AzureOpenAI_GPT_3_5}
                        onChange={(event) => {
                            sessionStore.setModelType(event.target.value)
                        }}
                        style={{ marginRight: '10px' }}
                    >
                        <MenuItem value={ModelOption.AzureOpenAI_GPT_3_5}>{ModelDisplay.AzureOpenAI_GPT_3_5}</MenuItem>
                        <MenuItem value={ModelOption.AzureOpenAI_GPT_4_O}>{ModelDisplay.AzureOpenAI_GPT_4_O}</MenuItem>
                    </Select> }
                </FormControl> */}
            
            {/* {sessionStore.group_select == 'Shared' ? <FileFilterRender selectedFile={sessionStore.currentFile} files={sessionStore.public_files} mode='Shared' /> : ( sessionStore.group_select == 'Personal' ? <FileFilterRender selectedFile={sessionStore.currentFile} files={sessionStore.personal_files} mode='Personal' /> : <></>)} */}
            {/* <ResponseModeSelection selectedMode={sessionStore.response_mode} modes={[ResponseMode.Balance, ResponseMode.Precise, ResponseMode.Creative]} /> */}
            </div>
            <div>
                <Button 
                    variant="contained" 
                    onClick={toggleDrawer(true)}
                    style={{ minWidth: '45px', padding: '0px', backgroundColor: '#016D77', borderRadius: '80px', marginRight: '10px' }} // Adjust as needed
                    
                >
                    <FolderIcon style={{ fontSize: '30px', paddingRight: isMobile ? (authStore.settingPaneAccessible ? '0px' : '5px') : '5px' }} /> {isMobile ? '' : ''}
                </Button>
                {!isMobile && authStore.settingPaneAccessible && ( <IconButton 
                    onClick={handleAdminModal}
                    style={{
                        marginRight: isMobile ? '30px' : '50px', // Right margin adjusts based on device
                        color: '#565656', // Dark gray color for the icon
                    }}
                >
                    <SettingsIcon style={{ fontSize: '24px' }}/>
                </IconButton>
                )}
            </div>
            <RightModal isOpen={drawerOpen} toggleDrawer={toggleDrawer} setDrawerOpen={setDrawerOpen} fetchNewFile={fetchNewFile} />
            
        </div>
    </>
)})
