import { makeAutoObservable } from 'mobx';
import { Severity } from '../enums/severity';

class NotiStore {
    isOpen = false;
    message = 'Default Notifications';
    severity = Severity.Info;
    hideIn = 5000;
    enqueueSnackbar = null
    closeSnackbar = null

    constructor() {
        makeAutoObservable(this);
    }

    setupNoti(
        enqueueSnackbar,
        closeSnackbar
    ) {
        this.enqueueSnackbar = enqueueSnackbar;
        this.closeSnackbar = closeSnackbar;
    }

    setOpen(open) {
        this.isOpen = open;
    }

    setMessage(message) {
        this.message = message;
    }

    setSeverity(severity) {
        this.severity = severity;
    }

    setHideIn(hideIn) {
        this.hideIn = hideIn;
    }

    close() {
        this.setOpen(false);
        this.setMessage('');
    }

    // { message: string; hideIn?: number; severity?: Severity; variant?: VariantType }
    open(trigger) {
        // Old way to show notification snack bar
        // const { message, severity, hideIn } = trigger;
        // this.setMessage(message);
        // this.setSeverity(severity ?? Severity.Info);
        // this.setOpen(true);
        // this.setHideIn(hideIn ?? 3000);
        const key = this.enqueueSnackbar(trigger.message, { variant: trigger.severity ?? Severity.Info });
        setTimeout(() => this.closeSnackbar(key), trigger.hideIn ?? 2500);
    }

    error(error, message) {
        if (error && error.response && error.response.data.message) {
            // this.enqueueSnackbar(trigger.message, { variant: this.severity ?? Severity.Info });
            this.open({ message: error.response.data.message, severity: Severity.Error });
        } else if(error && error.response && error.response.data.detail) {
            this.open({ message: error.response.data.detail, severity: Severity.Error });
        } else {
            this.open({ message: message, severity: Severity.Error });
        }
    }
}

export default NotiStore;
